import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import {trigger, transition, style, group, query, animate} from '@angular/animations';
import { Observable, Subscription } from 'rxjs';
import { SharedService } from './shared/shared.service';
import { AppUserAuth } from './shared/security/app-user-auth';
import { SecurityService } from './shared/security/security.service';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { StartupScreenDialogComponent } from './admin/user/detail/startup-screen-dialog/startup-screen-dialog.component';
import { UpDialogService } from '@ellira/upstrap-dialog';
import { PasswordDialogComponent } from './admin/user/detail/password-dialog/password-dialog.component';
import { PhoneNumberDialogComponent } from './shared/dialogs/phone-number-dialog/phone-number-dialog.component';
import { HttpClient, HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
      trigger('slideInOut', [
          transition('* => *, :enter', [
              query(':enter, :leave', style({position: 'absolute', width: '100%'}), {optional: true}),
              query(':enter', style({transform: 'translateX(100vw)'}), {optional: true}),
              query(':leave', style({transform: 'translateX(0vw)'}), {optional: true}),

              group([
                  query(':leave', [
                      animate('500ms ease-in-out', style({
                          transform: 'translateX(100vw)'
                      }))
                  ], {optional: true}),
                  query(':enter', [
                      animate('500ms ease-in-out', style({
                          transform: 'translateX(0)'
                      }))
                  ], {optional: true})
              ])
          ])
      ])
  ]
})
export class AppComponent implements OnInit {
    title = 'MoveIt';
    user: AppUserAuth = null;
    //chatUser: ISignalRUser;
    isNavigating = false;
    dialogSub: Subscription;
    imgURL: any;
	logoReady = false;

    isHideComms = true;

	phoneDialog = false;

    @ViewChild('appBar', {static: true}) appBar;
    @ViewChild('skyeDrawerLink', {static: true}) drawer;

    constructor(private securityService: SecurityService,
            public sharedService: SharedService,
            private router: Router,
            public viewContainerRef: ViewContainerRef,
			private http: HttpClient,
            private resolver: ComponentFactoryResolver,
            private dialogService: UpDialogService<StartupScreenDialogComponent>) {
        this.router.events.subscribe((event) => {
            switch (true) {
                case event instanceof NavigationStart: {
                    this.isNavigating = true;
                    break;
                }
                case event instanceof NavigationEnd:
                case event instanceof NavigationCancel:
                case event instanceof NavigationError: {
                    setTimeout(() => {
                        this.isNavigating = false;
                    }, 10);
                    break;
                }
                default:
                    break;
            }
        })
    }

    ngOnInit() {
        this.user = this.securityService.user;


        /* this.securityService.userModelReady.subscribe(val => {
            console.log("ready: ", val);
            console.log("admin?: ", this.user.admin);
            console.log("user2: ", this.user);
            if (!this.user.userModel) {
                console.log("actually has no userModel!!!!")
            }

            console.log("user?.hasModule('employee'): ", this.user.hasModule('employee'))
            console.log("user?.allowAccess('employee'): ", this.user?.allowAccess('employee', true))
        }) */
		
		this.securityService.showPhoneDialogEvent.subscribe(val => {
			
			//console.log("=========== app showPhoneDialog: ", val)
			this.phoneDialog = val;	

			if (this.phoneDialog) {
				if (this.dialogService.isOpen()) { return; }

				setTimeout(() => {
					this.dialogService.open(PhoneNumberDialogComponent, { data: null }, this.resolver);
					this.dialogSub = this.dialogService.afterClose().subscribe((d) => {
						if (d.dialogStatus === 'save') {
							console.log("save: ", d);
							this.postSetPhoneNumber(this.user.userModel.userName, this.user.userModel.userId, d.results.phoneNumber).subscribe(
								resp => {
									console.log("resp: ", resp);
								},
								error => {
									console.log("error: ", error);
								}
							)
						}
						// Unsubscribe to prevent multiple subscriptions:
						this.dialogSub.unsubscribe();
					})
				}, 1000);
			}
		})

        /* setTimeout(() => {
            this.chatUser = new ISignalRUser();
            this.chatUser.firstName = this.user.claimValue('firstName');
            this.chatUser.lastName = this.user.claimValue('lastName');
            this.chatUser.username = this.user.claimValue('username');
            this.chatUser.userId = parseInt(this.user.claimValue('userId'));
            this.chatUser.bearerToken = this.user.bearerToken;
            this.isHideComms = this.router.url === '/comms' || this.router.url === '/login' || this.router.url === '/';

            this.router.events.forEach(event => {
                if (event instanceof NavigationEnd) {
                    setTimeout(() => {
                        this.chatUser = new ISignalRUser();
                        this.chatUser.firstName = this.user.claimValue('firstName');
                        this.chatUser.lastName = this.user.claimValue('lastName');
                        this.chatUser.username = this.user.claimValue('username');
                        this.chatUser.userId = parseInt(this.user.claimValue('userId'));
                        this.chatUser.bearerToken = this.user.bearerToken;
                        this.isHideComms = !this.chatUser.bearerToken || this.router.url === '/comms' || this.router.url === '/login';
                    });
                }
            })
        }); */

        this.sharedService.appBar = this.appBar;
        this.sharedService.drawer = this.drawer;
        this.resetLogo();
        this.sharedService.logoReloadedEvent.subscribe(val => {
            this.resetLogo();
        });
    }


	
	getLogoUrl(companyId): Observable<HttpResponse<any>> {
		return this.http.get<any>(this.sharedService.adminApiUrl + 'logo?companyId=' + companyId, { observe: 'response' });
	}

	postSetPhoneNumber(userName: string, userId: number, phoneNumber: string): Observable<any> {
		return this.http.post<any>(this.sharedService.adminApiUrl + 'phonenumber', { userName: userName, userId: userId, phoneNumber: phoneNumber }, { observe: 'response' });
	}

    resetLogo() {
        /* const prevState = JSON.parse(localStorage.getItem('companyLogo'));
        if (prevState != null && this.user.userName == prevState.userName) {
            this.imgURL = prevState.imgURL;
        } */

		const prevState = localStorage.getItem('companyId');
		console.log("app prevState: ", prevState)
		if (prevState != null) {
			this.getLogoUrl(prevState).subscribe(
				resp => {
					console.log("resp: ", resp);
					this.imgURL = resp.body.logoUrl;
					this.logoReady = true;
				}
			)
			//this.imgURL = prevState;
			//console.log("imgURL: ", this.imgURL);
		} else {
			this.logoReady = true;
		}
    }

    logout(): void {
        this.isHideComms = true;
        localStorage.removeItem('url');
        this.securityService.logout();
    }

    triggerAnimation(outlet) {
        return outlet.activatedRouteData.animation || null;
    }

    routeLink(type) {
        localStorage.removeItem('url');
        localStorage.removeItem('isBack');
        setTimeout(() => {
            switch (type) {
                case 'VehicleList':
                    this.router.navigateByUrl('vehicles');
                    break;
                case 'TrailerList':
                    this.router.navigateByUrl('trailers');
                    break;
            }
        });
    }

    changeStartupScreen() {
        if (this.dialogService.isOpen()) { return; }

        this.dialogService.open(StartupScreenDialogComponent, { data: null }, this.resolver);
        this.dialogSub = this.dialogService.afterClose().subscribe((d) => {
            if (d.dialogStatus === 'save') {

            }
            // Unsubscribe to prevent multiple subscriptions:
            this.dialogSub.unsubscribe();
        })
    }


    changePassword() {
        if (this.dialogService.isOpen()) { return; }

        this.dialogService.open(PasswordDialogComponent, { data: null }, this.resolver);
        this.dialogSub = this.dialogService.afterClose().subscribe((d) => {
            if (d.dialogStatus === 'save') {
                console.log("data: ", d);
            }
            // Unsubscribe to prevent multiple subscriptions:
            this.dialogSub.unsubscribe();
        })
    }
}
