import { UserBaseModel } from 'src/app/admin/user/user-models';
import { AppUserClaim } from './app-user-claim';

export class AppUserAuth {
    userName = '';
    bearerToken = '';
    isAuthenticated = false;
    admin = false;
    claims: AppUserClaim[] = [];
    userModel: UserBaseModel;

    constructor(claims?: any) {
        this.claims = claims ? Object.entries(claims).map(([key, value]) => (new AppUserClaim(key, value))) : claims;
        this.admin = claims ? claims.admin == 'true' : this.userModel ? this.userModel.admin : false;
        this.userName = claims ? claims.email : null;
    }

    claimValue(claimName: string): string {
        if (this.claims == undefined) {
            return ''
        }
        for (let i = 0; i < this.claims.length; i++) {
            if (this.claims[i].type === claimName) {
                return this.claims[i].value;
            }
        }
        return '';
    }

	isDisabled(role: string, securityGroups: number[], isNew: boolean) : boolean {
		return (isNew && !this.allowCreate(role, securityGroups)) || (!isNew && this.editLevel(role, securityGroups) < 2)
	}



    allowCreate(role: string, securityGroups?: number[]): boolean {
        //console.log("editLevelSG: ", role, " : ", securityGroups);
        if (this.admin) { return true; }
        if (securityGroups && this.isSecurityGroupAdmin(securityGroups)) { return true; }
        //console.log("this.userModel.permissions: ", this.userModel?.permissions)
        if (this.userModel === undefined || this.userModel.permissions == undefined) {

            //console.log("return no user model"); 
            return false;
        }
        if ((securityGroups === undefined || securityGroups === null || securityGroups.length == 0) && this.userModel.securityGroups.find(x => x.admin)) {
            return true;
        }

        if (securityGroups === undefined || securityGroups === null || securityGroups.length == 0 || this.userModel.securityGroupRoles === undefined || this.userModel.securityGroupRoles.length === 0) {
            for (let i = 0; i < this.userModel.permissions.length; i++) {
                const permission = this.userModel.permissions[i];
                if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                    
                    // console.log("return no sg edit level: ", permission.editLevel); 
                    if (permission.allowCreate) {
                        return true
                    } else if (this.userModel.securityGroupRoles && this.userModel.securityGroupRoles.length > 0) {
                        const sgPermission = this.userModel.securityGroupRoles.filter(x => x.name.toLowerCase() == role.toLowerCase() && x.allowCreate)
                        if (sgPermission && sgPermission.length > 0) {
                            return true
                        } else {
                            return permission.allowCreate;
                        }
                    } else {
                        return permission.allowCreate;
                    }
                }
            }
        } else if (securityGroups && securityGroups.length > 0) {
            if (this.userModel.securityGroupRoles.filter(x => securityGroups.filter(y => Number(y) == Number(x.securityGroupId)).length > 0 && x.name.toLowerCase() == role.toLowerCase()).length > 0) {
                const permission = this.userModel.securityGroupRoles.filter(x => securityGroups.filter(y => Number(y) == Number(x.securityGroupId)).length > 0 && x.name.toLowerCase() == role.toLowerCase())[0];
                return permission.allowCreate;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /* allowCreate(role: string, branchId?: number): boolean {
        if (this.admin) { return true; }
        if (this.isBranchAdmin(branchId)) { return true; }
        // Todo: need to create separate method to handle this for global permissions (rather than branch specific permissions)
        if (branchId == undefined && this.hasAnyBranchAdmin()) { return true; }
        if (this.userModel === undefined || this.userModel.permissions == undefined) {
            return false;
        }
        if (branchId === undefined || branchId === null || this.userModel.userBranch === undefined || this.userModel.userBranch.length === 0) {
            for (let i = 0; i < this.userModel.permissions.length; i++) {
                const permission = this.userModel.permissions[i];
                if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                    return permission.allowCreate;
                }
            }
            if (this.userModel.userBranch && this.userModel.userBranch.length > 0) {
                for (let i = 0; i < this.userModel.userBranch.length; i++) {
                    for (let ii = 0; ii < this.userModel.userBranch[i].permissions.length; ii++) {
                        const permission = this.userModel.userBranch[i].permissions[ii];
                        if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                            if (permission.allowCreate) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else {
            if (typeof branchId == 'string') {
                branchId = parseInt(branchId);
            }
            if (this.userModel.userBranch.filter(b => b.branchId === branchId).length > 0) {
                for (let i = 0; i < this.userModel.userBranch.filter(b => b.branchId === branchId)[0].permissions.length; i++) {
                    const permission = this.userModel.userBranch.filter(b => b.branchId === branchId)[0].permissions[i];
                    if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                        return permission.allowCreate;
                    }
                }
            }
        }
        return false;
    } */

    allowCreateDebug(role: string, branchId?: number): boolean {
        console.log("allowCreateDebug: ", role)
        const debug = true;
        if (this.admin) { console.log("return admin"); return true; }
        if (this.isBranchAdmin(branchId)) { console.log("return branch admin"); return true; }
        if (branchId == undefined && this.hasAnyBranchAdmin()) { console.log("has any branch admin"); return true; }
        if (this.userModel === undefined || this.userModel.permissions == undefined) {
            console.log("return false 1: ")
            return false;
        }
        if (branchId === undefined || branchId === null || this.userModel.userBranch === undefined || this.userModel.userBranch.length === 0) {
            for (let i = 0; i < this.userModel.permissions.length; i++) {
                const permission = this.userModel.permissions[i];
                if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                    if (debug) { console.log("return 1: ", permission) }
                    return permission.allowCreate;
                }
            }
            if (this.userModel.userBranch && this.userModel.userBranch.length > 0) {
                for (let i = 0; i < this.userModel.userBranch.length; i++) {
                    for (let ii = 0; ii < this.userModel.userBranch[i].permissions.length; ii++) {
                        const permission = this.userModel.userBranch[i].permissions[ii];
                        if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                            if (permission.allowCreate) {
                                if (debug) { console.log("return 2: ", permission) }
                                return true;
                            }
                        }
                    }
                }
            }
        } else {
            if (typeof branchId == 'string') {
                branchId = parseInt(branchId);
            }
            if (this.userModel.userBranch.filter(b => b.branchId === branchId).length > 0) {
                for (let i = 0; i < this.userModel.userBranch.filter(b => b.branchId === branchId)[0].permissions.length; i++) {
                    const permission = this.userModel.userBranch.filter(b => b.branchId === branchId)[0].permissions[i];
                    if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {

                        if (debug) { console.log("return 3: ", permission) }

                        return permission.allowCreate;
                    }
                }
            }
        }
        console.log("return false 2: ")
        return false;
    }

    multipleBranchAccess(role: string) {
        if (this.admin) { return true; }
        if (this.userModel === undefined || this.userModel.permissions == undefined) {
            return false;
        }
        let totalBranchAccess = 0;
        if (this.userModel.userBranch === undefined || this.userModel.userBranch.length === 0) {
            for (let i = 0; i < this.userModel.permissions.length; i++) {
                const permission = this.userModel.permissions[i];
                if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                    totalBranchAccess++
                }
            }
            if (this.userModel.userBranch && this.userModel.userBranch.length > 0) {
                for (let i = 0; i < this.userModel.userBranch.length; i++) {
                    for (let ii = 0; ii < this.userModel.userBranch[i].permissions.length; ii++) {
                        const permission = this.userModel.userBranch[i].permissions[ii];
                        if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                            totalBranchAccess++
                        }
                    }
                }
            }
        } else {
            if (this.userModel.userBranch.length > 0) {
                for (let i = 0; i < this.userModel.userBranch.length; i++) {
                    for (let ii = 0; ii < this.userModel.userBranch[i].permissions.length; ii++) {
                        const permission = this.userModel.userBranch[i].permissions[ii];
                        if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                            totalBranchAccess++
                        }
                    }
                }
            }
        }
        if (totalBranchAccess <= 1) {
            return false
        } else {
            return true
        }
    }

    allowCreateAny(roles: string[], securityGroups?: number[]) {
        for (let i = 0; i < roles.length; i++) {
            if (this.allowCreate(roles[i], securityGroups)) {
                return true;
            }
        }
        return false;
    }

    /* allowCreateAny(roles: string[], branchId?: number) {
        for (let i = 0; i < roles.length; i++) {
            if (this.allowCreate(roles[i], branchId)) {
                return true;
            }
        }
        return false;
    } */

    allowDelete(role: string, securityGroups?: number[]): boolean {
        //console.log("allowDelete: ", role, " : ", securityGroups);
        if (this.admin) { return true; }
        if (securityGroups && this.isSecurityGroupAdmin(securityGroups)) { return true; }

        if (this.userModel === undefined || this.userModel.permissions == undefined) {
            //console.log("return no user model"); 
            return false;
        }
        if ((securityGroups === undefined || securityGroups === null || securityGroups.length == 0) && this.userModel.securityGroups.find(x => x.admin)) {
            return true;
        }
        if (securityGroups === undefined || securityGroups === null || securityGroups.length == 0 || this.userModel.securityGroupRoles === undefined || this.userModel.securityGroupRoles.length === 0) {
            for (let i = 0; i < this.userModel.permissions.length; i++) {
                const permission = this.userModel.permissions[i];
                if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                    //console.log("return no sg edit level: ", permission.editLevel); 
                    //return permission.allowDelete;

                    if (permission.allowDelete) {
                        return true
                    } else if (this.userModel.securityGroupRoles && this.userModel.securityGroupRoles.length > 0) {
                        const sgPermission = this.userModel.securityGroupRoles.filter(x => x.name.toLowerCase() == role.toLowerCase() && x.allowDelete)
                        if (sgPermission && sgPermission.length > 0) {
                            return true
                        } else {
                            return permission.allowDelete;
                        }
                    } else {
                        return permission.allowDelete;
                    }
                }
            }
        } else if (securityGroups && securityGroups.length > 0) {
            if (this.userModel.securityGroupRoles.filter(x => securityGroups.filter(y => Number(y) == Number(x.securityGroupId)).length > 0 && x.name.toLowerCase() == role.toLowerCase()).length > 0) {
                const permission = this.userModel.securityGroupRoles.filter(x => securityGroups.filter(y => Number(y) == Number(x.securityGroupId)).length > 0 && x.name.toLowerCase() == role.toLowerCase())[0];
                return permission.allowDelete;
            } else {
                //console.log("roles: ", this.userModel.securityGroupRoles)
                //console.log("roles: ", this.userModel.securityGroupRoles)
                //console.log("FALSE: ", role, " : ", securityGroups);
                //console.log("false 1: ", this.userModel.securityGroupRoles.filter(x => securityGroups.filter(y => Number(y) == Number(x.securityGroupId)).length > 0));
                return false;
            }
        } else {
            //console.log("false 2")
            return false;
        }
    }

    /* allowDelete(role: string, branchId?: number): boolean {
        console.log("allowDelete: ", role, " : ", branchId);
        if (this.admin) { return true; }
        if (this.isBranchAdmin(branchId)) { return true; }
        if (branchId == undefined && this.hasAnyBranchAdmin()) { return true; }
        if (this.userModel === undefined || this.userModel.permissions == undefined) {
            return false;
        }
        if (branchId === undefined || branchId === null || this.userModel.userBranch === undefined || this.userModel.userBranch.length === 0) {
            for (let i = 0; i < this.userModel.permissions.length; i++) {
                const permission = this.userModel.permissions[i];
                if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                    if (permission.allowDelete) {
                        return permission.allowDelete;
                    }
                }
            }
            if (this.userModel.userBranch && this.userModel.userBranch.length > 0) {
                for (let i = 0; i < this.userModel.userBranch.length; i++) {
                    for (let ii = 0; ii < this.userModel.userBranch[i].permissions.length; ii++) {
                        const permission = this.userModel.userBranch[i].permissions[ii];
                        if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                            if (permission.allowDelete) {
                                return true;
                            }
                        }
                    }
                }
            }
        } else {
            if (typeof branchId == 'string') {
                branchId = parseInt(branchId);
            }
            if (this.userModel.userBranch.filter(b => b.branchId === branchId).length > 0) {
                for (let i = 0; i < this.userModel.userBranch.filter(b => b.branchId === branchId)[0].permissions.length; i++) {
                    const permission = this.userModel.userBranch.filter(b => b.branchId === branchId)[0].permissions[i];
                    if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                        return permission.allowDelete;
                    }
                }
            }
        }
        return false;
    } */

    /* allowDeleteAny(roles: string[], branchId?: number) {
        for (let i = 0; i < roles.length; i++) {
            if (this.allowDelete(roles[i], branchId)) {
                return true;
            }
        }
        return false;
    } */

    allowDeleteAny(roles: string[], securityGroups?: number[]) {
        for (let i = 0; i < roles.length; i++) {
            if (this.allowDelete(roles[i], securityGroups)) {
                return true;
            }
        }
        return false;
    }

    editLevel(role: string, securityGroups?: number[], debug?: boolean): number {
		//if (role == 'claim') { debug = true }

		/*

		role:  collision
app-user-auth.ts:353 SG:  [6]
app-user-auth.ts:358 relavent:  [{…}]
app-user-auth.ts:359 editLevelSG:  collision  :  [6]

allowCreate
: 
true
description
: 
"Collision"
editLevel
: 
2
name
: 
"Collision"
permissions
: 
7
roleId
: 
15
securityGroupId
: 
6
securityGroupName
: 
"Mississauga"
userSecurityGroupId
: 
739
*/

        if (debug) {
            console.log("role: ", role);
            console.log("SG: ", securityGroups);
        }
        
        if (debug) {
            const relavent = this.userModel.securityGroupRoles.filter(x => x.permissions == 15);
            console.log("relavent: ", relavent);
            console.log("editLevelSG: ", role, " : ", securityGroups);
        }
        if (this.admin) { return 2; }
        if (securityGroups && this.isSecurityGroupAdmin(securityGroups)) { return 2; }

        if (this.userModel === undefined || this.userModel.permissions == undefined) {
			if (debug) {
				console.log("return no user model"); 
			}
            return 0;
        }

        if (this.userModel.securityGroups && this.userModel.securityGroups.length > 0) {
            if (this.userModel.securityGroups.find(x => x.admin)) {
                return 2;
            }
        }

        /* if (this.userModel.securityGroupRoles && this.userModel.securityGroupRoles.length > 0) {
            const matchedRoles = this.userModel.securityGroupRoles.filter(x => x x.name.toLowerCase() == role.toLowerCase());
   
            for (let i = 0; i < matchedRoles.length; i++) {
                const matchedRole = matchedRoles[i];
                if (matchedRole.allowCreate || matchedRole.allowDelete || matchedRole.editLevel > 0) {
                    return true;
                }
            }
        } */

        if (securityGroups === undefined || securityGroups === null || securityGroups.length == 0 || this.userModel.securityGroupRoles === undefined || this.userModel.securityGroupRoles.length === 0) {
            let maxPermissionLevel = 0
            for (let i = 0; i < this.userModel.permissions.length; i++) {
                const permission = this.userModel.permissions[i];
                if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                    //console.log("return no sg edit level: ", permission.editLevel); 
                    if (debug) {
                        console.log("return no sg edit level: ", permission); 
						console.log("this.userModel.securityGroupRoles: ", this.userModel.securityGroupRoles)
                    }
                    maxPermissionLevel = Math.max(maxPermissionLevel, permission.editLevel);
                    //return permission.editLevel;
                }
            }
            return maxPermissionLevel
        } else if (securityGroups && securityGroups.length > 0) {
            if (this.userModel.securityGroupRoles.filter(x => securityGroups.filter(y => Number(y) == Number(x.securityGroupId)).length > 0 && x.name.toLowerCase() == role.toLowerCase()).length > 0) {
                const permission = this.userModel.securityGroupRoles.filter(x => securityGroups.filter(y => Number(y) == Number(x.securityGroupId)).length > 0 && x.name.toLowerCase() == role.toLowerCase())[0];
                if (debug) {
					console.log("permission: ", permission)
					console.log("return permission.editLevel: ", permission.editLevel);
				}
				return permission.editLevel;
            } else {
				if (debug) {
					console.log("return 0 (1)");
				}
                return 0;
            }
        } else {
			if (debug) {
				console.log("return 0 (2)");
			}
            return 0;
        }

    }

    /* editLevel(role: string, branchId?: number, allowAnyBranch?: boolean): number {
        //if (debug) { console.log("user: ", this.userModel)}
        if (this.admin) { return 2; }
        if (this.isBranchAdmin(branchId)) { return 2; }
        if (allowAnyBranch) {
            if (this.hasAnyBranchAdmin()) { return 2; }
            for (let i = 0; i < this.userModel.permissions.length; i++) {
                var permission1 = this.userModel.permissions[i];
                if (permission1 && permission1.name && role && permission1.name.toLowerCase() === role.toLowerCase()) {
                    return permission1.editLevel;
                }
            }
            let maxPermissionLevel = 0;
            for (let j = 0; j < this.userModel.userBranch.length; j++) {
                const b = this.userModel.userBranch[j];
                for (let i = 0; i < b.permissions.length; i++) {
                    var permission2 = b.permissions[i];
                    if (permission2 && permission2.name && role && permission2.name.toLowerCase() === role.toLowerCase()) {
                        maxPermissionLevel = Math.max(maxPermissionLevel, permission2.editLevel);
                    }
                    if (j == this.userModel.userBranch.length-1 && i == b.permissions.length-1) {
                        return maxPermissionLevel;
                    }
                }
            }
        }
        if (this.userModel === undefined || this.userModel.permissions == undefined) {
            return 0;
        }
        if (branchId === undefined || branchId === null || this.userModel.userBranch === undefined || this.userModel.userBranch.length === 0) {
            for (let i = 0; i < this.userModel.permissions.length; i++) {
                const permission = this.userModel.permissions[i];
                if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                    return permission.editLevel;
                }
            }
        } else {
            if (typeof branchId == 'string') {
                branchId = parseInt(branchId);
            }
            if (this.userModel.userBranch.filter(b => b.branchId === branchId).length > 0) {
                for (let i = 0; i < this.userModel.userBranch.filter(b => b.branchId === branchId)[0].permissions.length; i++) {
                    const permission = this.userModel.userBranch.filter(b => b.branchId === branchId)[0].permissions[i];
                    if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                        return permission.editLevel;
                    }
                }
            }
        }
        return 0;
    } */

    allowAccess(role: string, test?: boolean): boolean {
        if (test) { console.log("allowAccess: ", role); console.log("admin: ", this.admin); console.log("userModel: ", this.userModel) }
        if (this.admin) { return true; }
        if (this.userModel === undefined || this.userModel == null || this.userModel.permissions == undefined) {
            if (test) {
                console.log("no user model: ", this.userModel);
            }
            return false;
        }
        if (test) {
            console.log("user permissions: ", this.userModel.permissions);
        }
        for (let i = 0; i < this.userModel.permissions.length; i++) {
            const permission = this.userModel.permissions[i];
            if (permission && permission.name && role && permission.name.toLowerCase() === role) {
                if (test) { console.log("permission: ", permission) }
                if (permission.editLevel > 0 || permission.allowCreate) {
                    if (test) {
                        console.log("true 1");
                    }
                    return true;
                }
            }
        }
        if (this.userModel.securityGroups && this.userModel.securityGroups.length > 0) {
            if (this.userModel.securityGroups.find(x => x.admin)) {
                if (test) {
                    console.log("test security group admin: ", this.userModel.securityGroups.find(x => x.admin))
                }
                return true;
            }
        }

        if (this.userModel.securityGroupRoles && this.userModel.securityGroupRoles.length > 0) {
            const matchedRoles = this.userModel.securityGroupRoles.filter(x => x.name.toLowerCase() == role.toLowerCase());
            if (test) {
                console.log("matchedRoles: ", matchedRoles)
            }
            for (let i = 0; i < matchedRoles.length; i++) {
                const matchedRole = matchedRoles[i];
                if (matchedRole.allowCreate || matchedRole.allowDelete || matchedRole.editLevel > 0) {
                    return true;
                }
            }
        }

        if (this.userModel.userBranch && this.userModel.userBranch.length > 0) {
            for (let i = 0; i < this.userModel.userBranch.length; i++) {
                for (let ii = 0; ii < this.userModel.userBranch[i].permissions.length; ii++) {

                    if (this.userModel.userBranch[i].admin) {
                        if (test) {
                            console.log(role, " branch admin return : ", this.userModel.userBranch[i].permissions.filter(x => x.name.toLowerCase() == role.toLowerCase()).length > 0);
                        }
                        return (this.userModel.userBranch[i].permissions.filter(x => x.name.toLowerCase() == role.toLowerCase()).length > 0);
                        // return true;
                    } else if (this.userModel.userBranch[i].permissions.filter(x => x.name.toLowerCase() == role.toLowerCase()).length > 0) {
                        if (test) {
                            console.log("true 3: ", this.userModel.userBranch[i].permissions.filter(x => x.name.toLowerCase() == role));
                        }
                        const p = this.userModel.userBranch[i].permissions.filter(x => x.name.toLowerCase() == role.toLowerCase())[0];
                        return p.allowCreate || p.allowDelete || p.editLevel > 0;
                    }

                    const permission = this.userModel.userBranch[i].permissions[ii];
                    if (permission && permission.name && role && permission.name.toLowerCase() === role.toLowerCase()) {
                        if (permission.editLevel > 0) {
                            if (test) {
                                console.log("true 4");
                            }
                            return true;
                        }
                    }
                }
            }
        }

        if (test) {
            console.log("false 2");
        }

        return false;
    }

    userIsType(type: string): boolean {
        if (this.admin) { return true; }
        for (let i = 0; i < this.claims.length; i++) {
            if (this.claims[i].type === 'userType') {
                return this.claims[i].value.toLowerCase() === type.toLocaleLowerCase();
            }
        }
    }

    filterBranchList(branchList, createRole?: string) {
        if (this.admin) { return branchList; }
        if (createRole == null || createRole == undefined) {
            const br = this.getBranchRestrictions();
            return branchList.filter(x => br.includes(x.branchId));
        } else {
            return branchList.filter(x => this.allowCreate(createRole, x.branchId));
        }
    }

	getSecurityGroupBranches(securityGroups, branches) {
		if (this.admin) { return branches}
		if (!this.userModel) {
			return []; // Return empty array if userModel is not defined
		}
	
		// Step 1: Get a set of securityGroupIds from userModel.securityGroups
		const userSecurityGroupIds = new Set(this.userModel.securityGroups.map(sg => sg.securityGroupId));
	
		// Step 2: Filter securityGroups array to include only those groups present in the user's security groups
		const relevantSecurityGroups = securityGroups.filter(sg => userSecurityGroupIds.has(sg.securityGroupId));
	
		// Step 3: Create a set of branchIds from the filtered securityGroups
		const relevantBranchIds = new Set(relevantSecurityGroups.map(sg => sg.branchId));
	
		// Step 4: Filter branches array to include only those branches whose branchId is in relevantBranchIds
		const relevantBranches = branches.filter(branch => relevantBranchIds.has(branch.branchId));
	
		return relevantBranches;
	}
	

    getBranchRestrictions() {
        let br = [];
        for (let i = 0; i < this.claims.length; i++) {
            if (this.claims[i].type === "branchRestrictions") {
                br.push(parseInt(this.claims[i].value))
            }
        }
        return br;
    }

    isBranchAdmin(branchId) {
        if (this.userModel && this.userModel.userBranch && this.userModel.userBranch.length > 0) {
            if (this.userModel.userBranch.filter(x => x.branchId == branchId).length > 0) {
                return this.userModel.userBranch.filter(x => x.branchId == branchId)[0].admin;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    hasAnyBranchAdmin() {
        if (this.userModel && this.userModel.userBranch && this.userModel.userBranch.length > 0) {
            for (let i = 0; i < this.userModel.userBranch.length; i++) {
                const branch = this.userModel.userBranch[i];
                if (branch.admin) { return true; }
                if (i == this.userModel.userBranch.length - 1) {
                    return false;
                }
            }
        } else {
            return false;
        }
    }

    isSecurityGroupAdmin(securityGroups?: number[]) {
        if (this.userModel && this.userModel.securityGroups && this.userModel.securityGroups.length > 0 && securityGroups) {
            try {
                if (this.userModel.securityGroups.filter(x => securityGroups.includes(x.securityGroupId)).length > 0) {
                    //console.log("return securityGroup admin: ", this.userModel.securityGroups.filter(x => securityGroups.includes(x.securityGroupId))[0].admin)
                    return this.userModel.securityGroups.filter(x => securityGroups.includes(x.securityGroupId))[0].admin;
                } else {
                    return false;
                }
                
            } catch (error) {
                return false
            }
        } else {
            return false;
        }
    }

    hasModule(mod: string) {
        //if (this.admin) { return true; }
        //console.log("claims: ", this.claims);
        if (this.claims == undefined) { return false; }
        const modName = mod.includes('module') ? mod.toLowerCase() : mod.toLowerCase() + 'module';
        if (!mod.includes('module')) { }
        const modClaim = this.claims.find(x => x.type.toLowerCase() == modName)
        if (modClaim != undefined && modClaim?.value == true || modClaim?.value == 'true') {
            return true
        } else {
            return false;
        }
        /* for (let i = 0; i < this.claims.length; i++) {
            if (this.claims[i].type === 'modules' && this.claims[i].value.toLowerCase() == mod.toLowerCase()) {
                return true;
            }
        }
        return false; */
    }

    editLevelBranches(role: string, branches: any[], noIds?: boolean) {
        if ((!branches || branches.length == 0) && !this.admin) { return 0 }
        if ((!branches || branches.length == 0) && this.admin) { return 2 }
        let eLevel = 0;
        branches.forEach(branch => {
            const el = this.editLevel(role, noIds ? branch : branch.branchId);
            eLevel = Math.max(eLevel, el);
        })
        return eLevel;
    }

    allowDeleteBranches(role: string, branches: any[], noIds?: boolean, test?: boolean): boolean {
        if (!branches || branches.length == 0) {
            return this.admin;
        }
        let check = false;
        branches.forEach(branch => {
            if (this.allowDelete(role, noIds ? branch : branch.branchId)) {
                check = true;
            }
        })
        return check;
    }
}


/*


31  :  Branch  :  Admin
29  :  User  :  Admin
33  :  Employees  :  Employee
92  :  Contact Details  :  Employee
85  :  Human Resources  :  Employee
60  :  Emergency Contacts  :  Employee
61  :  Licenses  :  Employee
62  :  Training  :  Employee
63  :  Incidents  :  Employee
65  :  Files  :  Employee
66  :  Notes  :  Employee
40  :  Agencies  :  EmployeeAdmin
44  :  Coverage Types  :  EmployeeAdmin
47  :  Departments  :  EmployeeAdmin
42  :  Employee Types  :  EmployeeAdmin
46  :  Forms  :  EmployeeAdmin
38  :  Incident Types  :  EmployeeAdmin
45  :  Languages  :  EmployeeAdmin
36  :  Positions  :  EmployeeAdmin
37  :  Status Types  :  EmployeeAdmin
32  :  Training Types  :  EmployeeAdmin
76  :  Equipment  :  Equipment
78  :  Inspections  :  Equipment
77  :  Servicing  :  Equipment
83  :  Files  :  Equipment
99  :  Notes  :  Equipment
88  :  Forms  :  EquipmentAdmin
97  :  Equipment Type  :  EquipmentAdmin
82  :  GPS  :  GeoTab
81  :  Move  :  Schedule
35  :  Schedule  :  Schedule
96  :  Notes  :  Schedule
89  :  Employee Dispatch Types  :  ScheduleAdmin
95  :  Locations  :  ScheduleAdmin
91  :  Trailer Dispatch Types  :  ScheduleAdmin
90  :  Vehicle Dispatch Types  :  ScheduleAdmin
98  :  Third Parties  :  ScheduleAdmin
43  :  Trailer  :  Trailer
74  :  Permits  :  Trailer
75  :  Inspections  :  Trailer
73  :  Servicing  :  Trailer
84  :  Files  :  Trailer
93  :  Notes  :  Trailer
87  :  Forms  :  TrailerAdmin
86  :  Trailer Types  :  TrailerAdmin
34  :  Vehicles  :  Vehicle
68  :  Permits  :  Vehicle
69  :  Inspections  :  Vehicle
67  :  Servicing  :  Vehicle
71  :  Files  :  Vehicle
72  :  Notes  :  Vehicle
54  :  Dispatch Lines  :  VehicleAdmin
53  :  Notification Types  :  VehicleAdmin
58  :  Forms  :  VehicleAdmin
55  :  Permit Types  :  VehicleAdmin
57  :  Service Providers  :  VehicleAdmin
56  :  Service Types  :  VehicleAdmin
52  :  Vehicle Types  :  VehicleAdmin


missing:

equipment notes - 99
equipment permits - does not exist
company  - admin only
capacity-calendar

division (schedule admin) ?

using same:
equipment service types
equipment permit types
equipment service providers
trailer service types
trailer permit types
trailer service providers




Branch : 31
User : 29
WorkOrder : 100
Employee : 33
EmployeeContactDetail : 92
EmployeeHumanResources : 85
EmployeeEmergencyContact : 60
EmployeeLicense : 61
EmployeeTraining : 62
EmployeeIncident : 63
EmployeeFile : 65
EmployeeNote : 66
EmployeeCoverageType : 44
EmployeeDepartment : 47
EmployeeType : 42
EmployeeForm : 46
EmployeeIncidentType : 38
EmployeeLanguage : 45
EmployeePosition : 36
EmployeeStatusType : 37
EmployeeTrainingType : 32
Equipment : 76
EquipmentInspection : 78
EquipmentServicing : 77
EquipmentFile : 83
EquipmentNote : 99
EquipmentForm : 88
EquipmentType : 97
GPS : 82
Move : 81
Schedule : 35
StickyNote : 96
EmployeeDispatchType : 89
MoveLocation : 95
TrailerDispatchType : 91
VehicleDispatchType : 90
ThirdParty : 98
Trailer : 43
TrailerPermit : 74
TrailerInspection : 75
TrailerServicing : 73
TrailerFile : 84
TrailerNote : 93
TrailerForm : 87
TrailerType : 86
Vehicle : 34
VehiclePermit : 68
VehicleInspection : 69
VehicleServicing : 67
VehicleFile : 71
VehicleNote : 72
VehicleDispatchLine : 54
VehicleNotificationType : 53
VehicleForm : 58
VehiclePermitType : 55
VehicleServiceProvider : 57
VehicleServiceType : 56
VehicleType : 52


*/
