<up-drawer #skyeDrawerLink type="overlay" [showNav]="user.isAuthenticated">
    <!--  <up-drawer startopen #skyeDrawerLink [showNav]="user.isAuthenticated"> -->

    <up-drawer-header title="Skye" hideTitles>
		<div *ngIf="logoReady && imgURL" class="company-logo" style="text-align: center;margin: 0 auto;padding-bottom:40px;max-height:80%;height:auto;max-width:400px;">
            <img [src]="imgURL" style="max-height: 250px;max-width: 100%;">
        </div>
		<div *ngIf="logoReady && !imgURL">
				
			<div *ngIf="user?.claimValue('companyId') != '1' && user?.claimValue('companyId') != '10'" style="padding: 15px 40px;">
				<svg  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 68 35" style="enable-background:new 0 0 68 35;" xml:space="preserve">
					<style type="text/css">
						.st0 {
							fill: #87CDE9;
						}
					</style>
					<g id="svg_10">
						<g id="svg_15" transform="matrix(0.4497006952389148,0,0,0.4497006952389148,-277.9246261602343,-227.49863655548296) ">
							<path id="svg_11" class="st0" d="M632.5,572.4c-2.8,0-5.3-0.6-7.4-1.8c-2.2-1.2-3.9-2.9-5.1-5.2l3.1-3.9c1.2,1.9,2.6,3.5,4.2,4.5
				c1.6,1.1,3.4,1.6,5.3,1.6c1.2,0,2.3-0.2,3.3-0.7c1-0.5,1.8-1.2,2.4-2.1c0.6-0.9,0.9-2,0.9-3.2c0-1.2-0.3-2.1-0.9-2.8
				c-0.6-0.7-1.5-1.3-2.7-1.8c-1.2-0.5-2.6-1.1-4.4-1.8c-1.4-0.6-2.8-1.2-4.1-1.9c-1.3-0.7-2.4-1.6-3.2-2.7c-0.8-1.1-1.3-2.5-1.3-4.2
				c0-1.8,0.5-3.5,1.5-4.9c1-1.4,2.4-2.5,4.1-3.3c1.7-0.8,3.7-1.2,6-1.2c1.8,0,3.6,0.4,5.3,1.1c1.7,0.7,3.3,1.8,4.9,3.1l-2.6,3.9
				c-2.3-2.4-4.8-3.6-7.5-3.6c-1.7,0-3,0.4-4,1.3c-1,0.8-1.5,1.9-1.5,3.3c0,1,0.4,1.9,1.1,2.6c0.7,0.7,1.7,1.3,2.9,1.8
				c1.2,0.5,2.4,1,3.6,1.5c1.9,0.7,3.5,1.4,4.9,2.2s2.5,1.7,3.3,2.9c0.8,1.2,1.2,2.7,1.2,4.7c0,2.3-0.5,4.3-1.6,5.9
				c-1.1,1.7-2.6,2.9-4.6,3.8C637.4,572,635.1,572.4,632.5,572.4L632.5,572.4z" />
							<path id="svg_12" class="st0" d="M656.4,571.3v-65.5h6.4v45.2l13.1-13.3h8.3l-15.6,16l16.4,17.6h-8.2l-14-15.2v15.2L656.4,571.3
				L656.4,571.3z" />
							<path id="svg_13" class="st0" d="M697.7,583.7l6.9-14.2l-16-31.8h7.2l12.4,25.4l11.1-25.4h7.2l-21.7,46H697.7z" />
							<path id="svg_14" class="st0" d="M750.4,572.4c-3.5,0-6.7-0.8-9.5-2.3c-2.8-1.6-5-3.7-6.6-6.3c-1.6-2.7-2.4-5.7-2.4-9.1
				c0-2.5,0.5-4.8,1.4-7c0.9-2.2,2.2-4.1,3.8-5.7c1.6-1.7,3.5-2.9,5.7-3.9c2.2-0.9,4.5-1.4,7-1.4c3.5,0,6.6,0.7,9.2,2.2
				c2.6,1.4,4.7,3.5,6.2,6c1.5,2.6,2.2,5.6,2.2,9c0,0.3,0,0.5,0,0.7c0,0.2-0.1,0.4-0.1,0.7h-28.9c0,2.3,0.5,4.4,1.6,6.2
				c1.1,1.8,2.5,3.2,4.4,4.2c1.9,1,4,1.5,6.4,1.5c2.3,0,4.4-0.5,6.2-1.4c1.8-0.9,3.4-2.3,4.7-4.2l4.7,2.4c-1.6,2.6-3.8,4.7-6.6,6.2
				C756.9,571.7,753.8,572.4,750.4,572.4z M738.6,550.6h22.3c-0.3-1.7-1-3.2-2-4.5c-1-1.3-2.3-2.3-3.9-3.1c-1.6-0.7-3.3-1.1-5.2-1.1
				c-2,0-3.7,0.4-5.3,1.1c-1.6,0.7-2.9,1.7-3.9,3C739.6,547.3,738.9,548.8,738.6,550.6L738.6,550.6z" />
						</g>
					</g>
				</svg>
			</div>
			<div *ngIf="user?.claimValue('companyId') == '10'">
				<img src="assets/tirecraft-logo.png" style="max-width: 100%;" alt="Tirecraft" />
			</div>
			<div *ngIf="user?.claimValue('companyId') == '1'">
				<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 500 500" xml:space="preserve">
					<style type="text/css">
						.st0 {
							fill: #ED192D;
						}

						.st1 {
							fill: #1D5FAD;
						}
					</style>
					<g>
						<path class="st0" d="M265.7,154c1.5,0.1,3.1,0.2,4.6,0.3c4.6,0.1,9.2,0.1,13.8,0.3c6.1,0.3,12.2,0.7,18.3,1.1
			c4.8,0.3,9.6,0.7,14.4,1.3c4.6,0.5,9.2,1.2,13.8,1.8c5.9,0.9,11.7,1.7,17.6,2.7c8,1.5,16,3,24,4.8c10.8,2.4,21.4,5.4,31.8,9.3
			c2,0.8,4.1,1.6,6.1,2.5c0.1,0,0.2,0.1,0.3,0.2c-3.1-0.8-6.1-1.7-9.1-2.3c-6.7-1.4-13.5-2.8-20.2-4c-5.4-1-10.8-1.8-16.3-2.6
			c-4.8-0.7-9.6-1.2-14.3-1.8c-4-0.5-8-1.1-12.1-1.6c-3.5-0.4-7-0.7-10.5-1c-3.7-0.3-7.4-0.6-11.1-0.9c-3.5-0.2-6.9-0.5-10.4-0.5
			c-10.1-0.2-20.1-0.4-30.2-0.5c-5.9,0-11.9,0.2-17.8,0.5c-6.1,0.3-12.3,0.7-18.4,1.2c-6.6,0.6-13.2,1.2-19.7,2.1
			c-9,1.2-18.1,2.5-27,4.1c-24.1,4.4-47.9,10.5-70.6,19.8c-14.3,5.9-28,12.9-40.5,22.1c-5.9,4.3-11.3,9.2-15.2,15.5
			c-2.6,4.1-4.2,8.5-4.2,13.4c0,6.2,2.4,11.5,6.2,16.2c6,7.3,13.5,13,21.6,17.8c12.6,7.5,26.2,12.6,40.3,16.7
			c12.4,3.6,24.9,6.8,37.6,9.3c8.1,1.6,16.2,2.9,24.3,4.2c7.8,1.2,15.6,2,23.4,2.2c5.4,0.1,10.9,0.4,16.3,0.5
			c12.8,0.1,25.7,0.2,38.5,0.2c5.5,0,11-0.2,16.5-0.3c4.7-0.1,9.5-0.3,14.2-0.5c0.2,0,0.4,0,0.5,0.2c-4.3,0.5-8.5,1-12.8,1.4
			c-4.6,0.5-9.1,1-13.7,1.4c-3.5,0.3-6.9,0.5-10.4,0.8c-6.3,0.4-12.6,0.8-18.9,1.2c-4,0.2-8.1,0.3-12.1,0.3
			c-10.4,0.1-20.7,0.2-31.1,0.1c-6.5-0.1-13.1-0.5-19.6-0.8c-4-0.2-8.1-0.4-12.1-0.8c-5.1-0.5-10.1-1-15.2-1.6
			c-6.4-0.8-12.7-1.6-19-2.7c-8.4-1.5-16.7-3.3-25-5.2c-13.9-3.2-27.5-7.5-40.7-12.9c-9-3.7-17.7-8-25.8-13.5
			c-5.9-4-11.4-8.6-15.7-14.5c-3.1-4.2-5.2-8.9-6-14.1c-0.1-0.9-0.2-1.8-0.3-2.7c0-1.4,0-2.7,0-4.1c0.2-1.4,0.3-2.9,0.6-4.3
			c1.3-7,4.3-13.2,8.6-18.8c5.8-7.5,13.1-13.3,21-18.4c16.1-10.3,33.3-18,51.5-23.9c11.2-3.6,22.5-6.6,34-9
			c6.8-1.5,13.7-2.7,20.5-3.9c5.2-0.9,10.4-1.7,15.6-2.4c5.3-0.8,10.6-1.5,16-2.1c5.2-0.6,10.4-1.1,15.6-1.5c5.9-0.5,11.8-1,17.6-1.4
			c5.6-0.3,11.3-0.4,16.9-0.5c1.5,0,3-0.2,4.5-0.3C252.7,154,259.2,154,265.7,154z" />
						<path class="st1" d="M475.9,291.3c-0.8,1.3-1.6,2.6-2.4,3.9c-0.2,0.4-0.8,0.8-1.1,0.8c-5.6,0-11.1,0-16.7,0c-0.1,0-0.3-0.1-0.6-0.1
			c0.9-1.6,1.7-3.1,2.6-4.7c2.4-4.2,4.8-8.5,7.1-12.7c0.4-0.8,0.9-1.1,1.8-1c1.9,0.1,3.7,0,5.8,0c-2.6,4.6-5,9-7.6,13.6
			c3.9,0,7.5,0,11.1,0C475.9,291.2,475.9,291.3,475.9,291.3z" />
						<path class="st1" d="M335.4,199.9c-1.4,2.3-2.7,4.4-4,6.5c-11.9,19.5-23.8,39-35.7,58.5c-0.4,0.6-0.7,1-1.6,1c-7.6,0-15.3,0-22.9,0
			c-0.2,0-0.5,0-0.9-0.1c9.6-15.7,19.2-31.3,28.7-46.9c-0.1-0.1-0.2-0.2-0.2-0.2c-0.3,0.2-0.6,0.4-0.8,0.7
			c-11.3,12.3-22.7,24.6-34,37c-2.7,3-5.5,6-8.2,8.9c-0.3,0.3-0.9,0.6-1.3,0.6c-6.4,0-12.9,0-19.3,0c-0.2,0-0.3,0-0.7-0.1
			c4.7-15.7,9.4-31.3,14.1-47c-0.1-0.1-0.2-0.1-0.3-0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-9.2,15.1-18.3,30.2-27.5,45.3
			c-0.3,0.5-0.9,1-1.4,1c-7.8,0.1-15.6,0-23.4,0c-0.1,0-0.2,0-0.5-0.1c1-1.6,1.9-3.2,2.9-4.8c12.1-19.9,24.1-39.8,36.2-59.8
			c0.6-1,1.2-1.3,2.4-1.3c11.9,0.1,23.8,0,35.8,0c0.4,0,0.9,0,1.5,0c-4,12.9-7.9,25.6-11.8,38.3c0.1,0.1,0.2,0.1,0.3,0.2
			c1.6-1.8,3.3-3.6,4.9-5.4c9.7-10.7,19.5-21.5,29.2-32.2c0.6-0.7,1.2-0.9,2.1-0.9c12,0,23.9,0,35.9,0
			C334.4,199.9,334.8,199.9,335.4,199.9z" />
						<path class="st1" d="M201.3,199.9c-0.4,2.3-0.7,4.5-1.1,6.7c-0.8,4.7-1.5,9.5-2.3,14.2c-0.8,4.7-1.5,9.5-2.2,14.2
			c-0.8,4.8-1.6,9.6-2.3,14.3c-0.8,5.2-1.6,10.4-2.5,15.5c-0.1,0.3-0.6,0.9-1,0.9c-9.2,0-18.4,0-27.6,0c-0.1,0-0.2-0.1-0.5-0.2
			c0.5-2.4,1-4.7,1.5-7.3c-0.6,0-1,0-1.5,0c-8.7,0-17.4,0-26.2,0c-0.9,0-1.5,0.3-2.1,0.9c-2,2-3.9,4-6,5.9c-0.4,0.3-0.9,0.7-1.4,0.7
			c-9.2,0-18.5,0-27.7,0c-0.1,0-0.3,0-0.6-0.1c1.9-1.8,3.7-3.5,5.6-5.2c9.6-9,19.1-18,28.7-27.1c11.6-10.9,23.2-21.9,34.8-32.8
			c0.5-0.4,1.2-0.8,1.9-0.8c10.4,0,20.9,0,31.3,0C200.5,199.9,200.8,199.9,201.3,199.9z M172.5,220c-0.1-0.1-0.3-0.2-0.4-0.2
			c-7.2,7.5-14.4,15-21.6,22.4c0.1,0.1,0.1,0.2,0.2,0.3c0.4,0,0.8,0,1.3,0c4.6,0,9.1-0.1,13.7,0c1.2,0,1.6-0.4,1.8-1.5
			c1.2-5.1,2.5-10.2,3.8-15.4C171.6,223.8,172,221.9,172.5,220z" />
						<path class="st1" d="M414.6,199.9c-1.7,2.8-3.2,5.3-4.8,7.9c-7.1,11.7-14.2,23.4-21.4,35c-4.2,6.9-9.3,12.9-16.2,17.4
			c-5.8,3.8-12.1,5.9-18.9,7.1c-6.3,1.1-12.7,1.4-19.1,1.1c-5.4-0.2-10.9-0.5-16-2.6c-5-2.1-6.6-5.7-6.2-9.8c0.3-3.3,1.5-6.4,3-9.4
			c1.2-2.4,2.5-4.8,3.9-7.1c0.2-0.4,0.8-0.8,1.3-0.8c8.6,0,17.2,0,25.8,0c0.1,0,0.3,0,0.6,0.1c-0.7,1.2-1.4,2.2-1.9,3.4
			c-0.9,1.7-1.7,3.3-2.4,5.1c-0.3,0.6-0.4,1.4-0.4,2.1c-0.1,1.7,0.7,2.7,2.4,3c4.1,0.8,7.8-0.1,11-2.6c2.7-2.1,4.7-4.9,6.4-7.8
			c8.3-13.6,16.5-27.2,24.7-40.8c0.5-0.9,1.1-1.2,2.2-1.2c8.2,0.1,16.3,0,24.5,0C413.6,199.9,414,199.9,414.6,199.9z" />
						<path class="st0" d="M420.8,185.4c2,0.5,4.1,1.1,6.2,1.6c1.3,0.3,1.6,0,1.7-1.2c0.1-1,0-2,0-3.3c0.7,1,1.3,1.8,1.8,2.5
			c1.3,1.7,2.5,3.4,3.8,5.1c0.3,0.4,0.7,0.7,1.2,1c0.9,0.6,1.9,0.1,1.8-1c-0.1-1.5-0.5-3.1-0.9-4.6c-0.9-3.5-1.8-6.9-2.7-10.6
			c0.9,0.7,1.7,1.3,2.4,1.8c1.6,1,2.6,0.9,3.5-0.7c1.1-1.9,1.9-3.9,2.8-5.9c0.1,0.3,0.2,0.8,0.4,1.2c0.7,1.6,1.3,3.3,2.2,4.8
			c0.9,1.5,1.8,1.6,3.3,0.7c0.4-0.3,0.8-0.6,1.3-0.9c0.4-0.3,0.8-0.6,1.3-1c-1,3.9-2,7.5-3,11.1c-0.3,1.1-0.6,2.1-0.7,3.2
			c-0.1,0.6,0.1,1.5,0.5,1.8c0.4,0.2,1.4,0,1.7-0.4c1.4-1.5,2.6-3,3.8-4.6c0.9-1.1,1.6-2.2,2.5-3.4c0,1.1,0,2.1,0,3.1
			c0,1,0.5,1.6,1.6,1.4c1.7-0.4,3.5-0.8,5.2-1.2c0.4-0.1,0.8-0.3,1.4-0.5c-0.5,1-0.9,1.7-1.2,2.5c-0.4,1-0.7,2-1,3.1
			c-0.4,1.5,0.2,2.3,1.7,2.6c0.9,0.2,1.8,0.2,3,0.4c-1.9,1.4-3.7,2.6-5.4,3.9c-1.9,1.4-3.7,2.8-5.6,4.2c-1.5,1.1-2.1,2.4-1.5,4.2
			c0.1,0.3,0.1,0.7,0.3,1.2c-2.4-0.6-4.7-1.2-7-1.8c-0.6-0.2-1.2-0.3-1.9-0.4c-1.5-0.3-2.2,0.2-2.4,1.7c-0.2,1.1-0.2,2.3-0.2,3.5
			c0,2.2,0,4.5,0,6.8c-0.5,0-0.9,0-1.4,0c0-3,0-5.9,0-8.9c0-0.4,0-0.9,0-1.3c-0.2-1.5-1.1-2.1-2.5-1.7c-2.5,0.6-5,1.2-7.5,1.8
			c-0.3,0.1-0.7,0.1-1.2,0.2c0.1-0.7,0.1-1.4,0.3-1.9c0.4-1.2-0.1-2.1-1-2.8c-1.9-1.6-3.9-3.1-5.8-4.6c-1.8-1.4-3.7-2.7-5.7-4.2
			c1.1-0.1,1.9-0.2,2.8-0.4c1.9-0.4,2.4-1.1,1.9-3C422.1,188.7,421.3,187.1,420.8,185.4z" />
						<path class="st1" d="M334.2,296.1c1.2-2.2,2.3-4.2,3.4-6.1c2.2-3.9,4.3-7.7,6.5-11.6c0.2-0.4,0.7-0.8,1-0.8c3-0.1,5.9,0,8.9,0
			c0.1,0,0.2,0.1,0.4,0.2c-1,3.4-1.9,6.8-2.9,10.2c0.1,0,0.2,0.1,0.2,0.1c0.6-0.7,1.3-1.5,1.9-2.2c2.1-2.5,4.2-5.1,6.4-7.6
			c0.3-0.3,0.8-0.6,1.2-0.6c3-0.1,6,0,9.2,0c-0.2,0.5-0.4,0.8-0.5,1.1c-3.1,5.5-6.2,11.1-9.3,16.6c-0.2,0.3-0.6,0.8-0.9,0.8
			c-1.9,0.1-3.7,0-5.9,0c2.4-4.3,4.6-8.3,6.9-12.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2,0.3-0.4,0.5-0.6,0.8c-3.1,3.7-6.3,7.5-9.5,11.1
			c-0.4,0.4-1,0.7-1.6,0.7c-1.3,0.1-2.7,0-4.3,0c1.2-4.3,2.3-8.4,3.4-12.6c-0.1,0-0.2-0.1-0.2-0.1c-0.2,0.3-0.4,0.5-0.5,0.8
			c-2.1,3.7-4.2,7.4-6.2,11.1c-0.3,0.6-0.7,0.9-1.4,0.8C337.9,296,336.2,296.1,334.2,296.1z" />
						<path class="st1" d="M387.9,295.9c0.9-1.7,1.8-3.3,2.7-4.9c2.3-4.1,4.7-8.3,6.9-12.4c0.5-0.8,1-1.1,2-1.1c5,0.1,10,0,15,0
			c3.4,0,4.3,1.6,2.6,4.6c-0.9,1.5-1.7,2.2-4,3.6c1.7,1.4,1.2,3,0.3,4.6c-2.1,3.6-5.2,5.6-9.4,5.7c-5.2,0.1-10.4,0-15.6,0
			C388.3,296.1,388.2,296,387.9,295.9z M397.1,291.6c2.4,0,4.6,0.1,6.7-0.1c0.7,0,1.4-0.7,2-1.2c0.3-0.3,0.4-0.9,0.3-1.3
			c-0.1-0.3-0.7-0.5-1.1-0.5c-1.8-0.1-3.7,0-5.5,0c-0.3,0-0.7,0.2-0.9,0.4C398.1,289.8,397.7,290.6,397.1,291.6z M401.3,284.5
			c2,0,4,0.1,5.9,0c0.5,0,1.1-0.4,1.5-0.8c0.3-0.3,0.5-0.9,0.4-1.2c0-0.2-0.7-0.4-1.1-0.5c-1.2-0.1-2.3,0-3.5,0
			C402.2,281.9,402.2,281.9,401.3,284.5z" />
						<path class="st1" d="M312.8,289c-3.3,4.6-7.5,7.2-12.9,7.5c-2.6,0.2-5.3,0.1-7.9-0.3c-3.4-0.5-4.6-2.7-3.6-5.9
			c2.2-6.8,7.9-12.9,16.4-13.3c2.4-0.1,4.9-0.1,7.3,0.3c4.3,0.7,4.5,3.6,3.3,6.9c-0.1,0.2-0.4,0.5-0.6,0.5c-2.4,0-4.8,0-7.2,0
			c-0.1-0.2-0.2-0.3-0.1-0.4c0.3-2.2-0.4-2.9-2.6-2.9c-2,0-3.7,0.8-5.1,2.1c-1.6,1.6-2.8,3.4-3.5,5.6c-0.5,1.6,0.3,2.8,2,2.9
			c2.2,0.2,4.1-0.6,5.6-2.2c0.7-0.7,1.3-1,2.3-0.9C308.4,289,310.5,289,312.8,289z" />
						<path class="st1" d="M442.6,277.6c-0.8,1.4-1.4,2.7-2.2,3.9c-0.2,0.3-0.7,0.4-1.1,0.4c-3.1,0-6.2,0-9.2,0c-2.2,0-2.4,0.1-3.2,2.6
			c3.6,0,7.2,0,11,0c-0.9,1.5-1.5,2.8-2.3,4.1c-0.2,0.3-0.8,0.3-1.2,0.3c-2.7,0-5.4,0-8.2,0c-2.3,0-2.6,0.2-3.3,2.7
			c3.9,0,7.7,0,11.9,0c-0.9,1.5-1.6,2.9-2.4,4.2c-0.1,0.2-0.5,0.3-0.7,0.3c-6.1,0-12.1,0-18.4,0c0.2-0.4,0.2-0.7,0.4-0.9
			c3.1-5.6,6.2-11.1,9.4-16.7c0.3-0.5,0.5-0.9,1.3-0.9c5.9,0,11.8,0,17.6,0C442.1,277.5,442.3,277.6,442.6,277.6z" />
						<path class="st1" d="M363.8,296.1c2.5-4.4,4.8-8.6,7.2-12.8c0.9-1.6,1.9-3.3,2.7-4.9c0.3-0.6,0.7-0.9,1.4-0.8c4.8,0,9.7,0,14.5,0
			c0.7,0,1.4,0.2,2.1,0.4c1.4,0.5,2,1.5,1.6,3c-1.2,5.4-6.1,9.3-11.7,9.3c-2.2,0-4.4,0-6.6,0c-0.7,0-1.2,0.2-1.5,0.9
			c-0.7,1.4-1.5,2.7-2.3,4.1c-0.3,0.6-0.7,0.9-1.4,0.9C368,296,366,296.1,363.8,296.1z M376.6,285.8c2,0,3.7,0.1,5.4,0
			c1.3-0.1,2.3-0.8,3-1.9c0.7-1.3,0.4-2-1.1-2.1c-1.5-0.1-3,0-4.4,0c-0.3,0-0.7,0.2-0.8,0.4C378,283.3,377.4,284.4,376.6,285.8z" />
						<path class="st1" d="M335.8,277.5c-0.2,1.7-0.4,3.2-0.6,4.7c-0.6,4.2-1.3,8.4-1.9,12.6c-0.1,0.8-0.4,1.2-1.3,1.2c-2-0.1-4,0-6.2,0
			c0.1-0.7,0.1-1.4,0.2-2.1c-2.4,0-4.7-0.1-7.1,0.1c-0.6,0-1.1,0.9-1.7,1.3c-0.5,0.3-1.1,0.7-1.6,0.7c-2,0.1-4.1,0-6.4,0
			c1.3-1.3,2.4-2.5,3.5-3.6c4.6-4.7,9.1-9.4,13.7-14.1c0.6-0.6,1.2-0.9,2.1-0.9C330.8,277.6,333.2,277.5,335.8,277.5z M322.8,289.2
			c0,0.1,0.1,0.2,0.1,0.3c1.2,0,2.4,0,3.5,0c0.2,0,0.6-0.3,0.7-0.5c0.4-1.8,0.8-3.7,1.2-5.5c-0.1-0.1-0.2-0.1-0.3-0.2
			C326.3,285.2,324.5,287.2,322.8,289.2z" />
						<path class="st1" d="M375.4,345.5c2.8-4.7,5.5-9.1,8.2-13.5c-0.1-0.1-0.1-0.1-0.2-0.2c-0.2,0.1-0.4,0.2-0.5,0.3
			c-3.1,2.6-6.3,5.3-9.4,8c-0.7,0.6-1.5,0.8-2.5,0.5c0-2.9,0-5.8,0-9.1c-0.4,0.4-0.6,0.6-0.7,0.9c-2.4,3.8-4.8,7.6-7.1,11.5
			c-0.7,1.2-1.5,1.8-2.9,1.6c-0.4-0.1-0.8,0-1.4,0c0.8-1.3,1.5-2.5,2.2-3.6c3-4.9,6.1-9.8,9.1-14.8c0.7-1.2,1.5-1.9,2.9-1.6
			c0.5,0.1,0.9,0,1.4,0c0,3.7,0,7.3,0,11.1c0.7-0.6,1.3-1.2,1.9-1.7c3.5-2.9,6.9-6,10.5-8.7c1-0.7,2.6-0.6,3.9-0.8
			c0.1,0.1,0.1,0.2,0.2,0.4c-0.6,1-1.3,2.1-1.9,3.1c-3.1,5.1-6.3,10.1-9.3,15.2c-0.7,1.2-1.5,1.8-2.8,1.5
			C376.5,345.4,376.1,345.5,375.4,345.5z" />
						<path class="st1" d="M44.1,340.6c0-3.1,0-6.1,0-9.4c-0.4,0.6-0.7,0.9-1,1.3c-2.3,3.9-4.7,7.8-7,11.7c-0.6,1-1.2,1.5-2.3,1.3
			c-0.5-0.1-1,0-1.8,0c0.6-1.1,1.1-2,1.7-2.9c3.1-5.1,6.3-10.3,9.3-15.5c0.8-1.3,1.6-1.9,3.1-1.7c0.3,0.1,0.7,0,1.1,0
			c0,3.7,0,7.4,0,11.2c0.5-0.4,0.9-0.7,1.3-1c3.4-3,6.8-5.9,10.2-8.9c1-0.9,2.1-1.6,3.5-1.3c0.5,0.1,1,0,1.7,0
			c-0.2,0.5-0.4,0.8-0.6,1.2c-3.6,5.8-7.2,11.5-10.7,17.3c-0.7,1.2-1.4,1.7-2.7,1.5c-0.5-0.1-1,0-1.7,0c3-4.7,5.8-9.3,8.6-13.8
			c-0.1-0.1-0.2-0.2-0.2-0.2c-0.9,0.8-1.9,1.6-2.8,2.3c-2.4,2-4.8,4.1-7.2,6.2C45.9,340.6,45.2,340.9,44.1,340.6z" />
						<path class="st1" d="M455.6,291.1c-0.9,1.7-1.7,3.1-2.6,4.5c-0.1,0.2-0.6,0.4-0.9,0.4c-5.7,0-11.3,0-17,0c-0.1,0-0.1-0.1-0.3-0.1
			c1.1-1.9,2.1-3.8,3.2-5.7c2.2-3.9,4.4-7.8,6.6-11.8c0.4-0.6,0.8-0.9,1.5-0.9c1.9,0,3.8,0,6,0c-2.5,4.6-5,9-7.6,13.6
			C448.3,291.1,451.8,291.1,455.6,291.1z" />
						<path class="st1" d="M416.3,330.4c-0.2,2.3-1.4,4.8-3.1,7.1c-2.8,3.9-6.4,6.9-11.2,7.8c-1.7,0.3-3.5,0.3-5.2,0.1
			c-2.6-0.4-3.9-2.7-3.3-5.3c1.9-7.4,6.6-12.3,14-14.4c1.6-0.5,3.6-0.4,5.3-0.1C415.2,325.9,416.3,327.5,416.3,330.4z M400.9,342.8
			c0.5-0.1,1.1-0.1,1.6-0.3c5.2-1.7,8.4-5.4,9.8-10.6c0.7-2.6-0.9-4.3-3.8-3.9c-4.8,0.7-9.8,5.8-10.9,10.6
			C396.9,341.5,398.7,343,400.9,342.8z" />
						<path class="st1" d="M170.5,340.7c0.2-2.1,1.2-4.2,2.6-6.3c2.8-4.2,6.3-7.6,11.4-8.7c1.7-0.4,3.6-0.4,5.4-0.1
			c2.7,0.4,4,2.6,3.3,5.3c-2,7.5-6.6,12.4-14.1,14.5c-1.9,0.5-3.8,0.6-5.6-0.1C171.4,344.5,170.4,343.3,170.5,340.7z M177.9,342.7
			c0.3,0,0.6,0,0.8,0c0.2,0,0.4-0.1,0.6-0.1c5.2-1.6,8.3-5.3,9.9-10.3c1.1-3.4-1.5-4.6-3.8-4.2c-5,0.8-9.8,5.8-10.7,10.8
			C174.2,341.5,175.2,342.7,177.9,342.7z" />
						<path class="st1" d="M274.5,335c1.5,1.3,1.7,2.5,0.5,4.4c-2.4,3.9-6,5.7-10.5,5.9c-2.1,0.1-4.2,0-6.5,0c1-1.7,1.9-3.2,2.8-4.7
			c2.9-4.8,5.8-9.6,8.7-14.4c0.4-0.7,0.8-1,1.6-0.9c1.7,0.1,3.4,0,5.2,0c1.3,0,2.5,0.4,3.1,1.6c0.6,1.2,0.3,2.5-0.5,3.5
			C277.6,332.1,276,333.5,274.5,335z M263,342.6c2.4,0.2,4.5,0.2,6.4-1c0.9-0.6,1.8-1.5,2.3-2.4c0.8-1.3,0.1-2.4-1.4-2.6
			c-1-0.1-2.1,0-3.1,0c-0.2,0-0.5,0.1-0.6,0.2C265.4,338.7,264.3,340.5,263,342.6z M268.4,333.7c2.9,0.5,5.1-0.1,6.8-2.4
			c0.6-0.8,1.1-1.7,0.3-2.6c-0.6-0.6-3.4-0.9-3.8-0.3C270.5,330.1,269.5,331.9,268.4,333.7z" />
						<path class="st1" d="M237.2,345.4c-1.4,0-2.5,0-3.8,0c0-2.2,0-4.3,0-6.4c0-0.5-0.3-1.3-0.6-1.4c-0.7-0.2-1.5-0.3-2,0.7
			c-1.1,2-2.4,3.9-3.5,5.8c-0.6,1.1-1.3,1.5-2.5,1.3c-0.5-0.1-1,0-1.6,0c0.2-0.5,0.4-0.8,0.6-1.1c3.6-6,7.3-12,10.9-18
			c0.4-0.6,0.8-0.9,1.5-0.9c2.1,0.1,4.2,0,6.3,0.3c2.1,0.3,2.9,1.7,2.3,3.8c-0.9,3.5-3.4,5.6-6.7,6.7c-1.1,0.4-1.3,0.9-1.2,1.9
			C237,340.5,237.1,342.9,237.2,345.4z M233,334.6c1.1,0,1.8,0.1,2.6,0c2.3-0.3,4.2-1.3,5.2-3.5c0.4-0.9,0.5-1.7-0.4-2.3
			c-0.8-0.6-3.2-0.7-3.6-0.1C235.5,330.4,234.4,332.4,233,334.6z" />
						<path class="st1" d="M224.2,325.4c-2.1,3.5-4.2,7-6.4,10.4c-1.4,2.1-2.8,4.2-4.4,6.1c-3,3.4-7,4-11.3,3.5c-2.5-0.3-3.7-2.4-2.7-4.7
			c1-2.3,2.2-4.5,3.5-6.7c1.5-2.5,3.1-4.9,4.6-7.4c0.6-1.1,1.3-1.6,2.6-1.4c0.5,0.1,1,0,1.7,0c-0.4,0.7-0.7,1.3-1.1,1.8
			c-2.1,3.4-4.2,6.8-6.2,10.3c-0.5,0.8-0.8,1.8-1.1,2.7c-0.6,1.6,0.7,2.7,2.1,2.7c2.8,0,5.3-0.9,6.9-3.4c2.4-3.8,4.7-7.7,7-11.6
			c0,0,0-0.1,0.1-0.1C220.1,325.4,221.7,324.7,224.2,325.4z" />
						<path class="st1" d="M103.4,345.4c-1.3,0-2.4,0.1-3.6,0c-0.3,0-0.8-0.6-0.9-1c-0.5-2-1-4.1-1.5-6.1c-0.1-0.4-0.2-0.8-0.4-1.5
			c-0.3,0.4-0.6,0.7-0.7,0.9c-1.3,2.1-2.7,4.2-3.8,6.3c-0.6,1.1-1.3,1.6-2.6,1.4c-0.5-0.1-0.9,0-1.7,0c1.1-1.7,2-3.3,2.9-4.8
			c2.8-4.6,5.6-9.1,8.3-13.7c0.7-1.1,1.4-1.6,2.7-1.4c0.5,0.1,1,0,1.8,0c-1.8,3-3.6,5.7-5.3,8.5c0.1,0.1,0.2,0.2,0.2,0.2
			c1.2-0.9,2.5-1.8,3.7-2.7c2.2-1.6,4.4-3.2,6.5-4.9c1.1-0.9,2.2-1.4,3.6-1.2c0.6,0.1,1.2,0,2.1,0c-1.6,1.2-3,2.2-4.3,3.2
			c-2.9,2.1-5.7,4.2-8.7,6.2c-0.8,0.6-0.9,1.1-0.7,2c0.8,2.5,1.4,5,2.1,7.6C103.3,344.7,103.3,345,103.4,345.4z" />
						<path class="st1" d="M79.1,345.4c-1.2,0-2.2,0-3.5,0c0.3-1.7,0.6-3.3,0.9-5c-2.3,0-4.4,0-6.5,0c-0.3,0-0.7,0.3-1,0.6
			c-1,1.1-2.1,2.2-3,3.4c-0.6,0.8-1.3,1.1-2.2,1c-0.7-0.1-1.5,0-2.5,0c1.4-1.5,2.6-2.8,3.9-4.2c4.8-5.1,9.6-10.1,14.4-15.2
			c1-1.1,2.2-0.4,3.5-0.7C81.8,332.2,80.4,338.8,79.1,345.4z M72.6,337.4c0.7,0,1,0.1,1.3,0.1c3.4,0,3.4,0,4.2-3.3
			c0.3-1.2,0.6-2.4,0.9-3.5c-0.1,0-0.2-0.1-0.3-0.1C76.7,332.7,74.8,334.9,72.6,337.4z" />
						<path class="st1" d="M446.5,325.5c-3.4,3.6-6.7,6.9-9.9,10.3c-2.7,2.8-5.5,5.6-8.1,8.5c-1.1,1.2-2.3,1.4-3.9,1.1
			c0.2-1.2,0.4-2.4,0.6-3.6c0.9-4.9,1.9-9.8,2.8-14.7c0.2-1.1,0.5-1.8,1.8-1.6c0.6,0.1,1.2,0,2.1,0c-1,4.9-1.9,9.6-2.9,14.4
			c0.1,0.1,0.1,0.1,0.2,0.2c0.3-0.3,0.6-0.6,0.9-0.9c3.8-4.1,7.6-8.2,11.4-12.3c0.9-1,1.8-1.5,3.1-1.3
			C445.1,325.5,445.7,325.5,446.5,325.5z" />
						<path class="st1" d="M304.7,325.5c-0.5,0.9-0.9,1.7-1.4,2.4c-0.2,0.2-0.7,0.4-1,0.4c-1.9,0-3.8,0-5.6,0c-0.7,0-1.2,0.2-1.5,0.8
			c-0.8,1.5-1.7,2.9-2.7,4.6c2.3,0,4.4,0,6.6,0c-0.6,1-1,1.8-1.6,2.6c-0.2,0.2-0.7,0.4-1,0.4c-1.6,0-3.2,0-4.8,0
			c-0.7,0-1.2,0.2-1.5,0.8c-1,1.7-2,3.3-3.1,5.2c2.6,0,4.9,0,7.2,0c-0.7,2.4-1.3,2.8-3.4,2.8c-2.9,0-5.7,0-8.8,0c1.1-1.8,2-3.4,3-5
			c2.8-4.7,5.7-9.3,8.5-14c0.5-0.8,1-1.1,1.9-1C298.2,325.5,301.3,325.5,304.7,325.5z" />
						<path class="st1" d="M460.6,333.7c-0.6,1-1,1.8-1.5,2.4c-0.2,0.3-0.7,0.4-1,0.4c-1.6,0-3.1,0.1-4.7,0c-0.7,0-1.2,0.2-1.5,0.8
			c-0.9,1.7-2,3.3-3.1,5.2c2.5,0,4.7,0,7,0c-0.5,2.3-1.2,2.9-3.3,2.9c-2.9,0-5.7,0-8.8,0c0.3-0.5,0.5-0.9,0.7-1.2
			c3.6-6,7.2-11.9,10.8-17.9c0.4-0.6,0.8-0.9,1.5-0.9c3.2,0,6.4,0,9.6,0c-0.5,2.2-1.2,2.7-3.3,2.7c-1.5,0-3-0.1-4.6,0
			c-0.5,0-1.1,0.4-1.4,0.8c-1,1.4-1.8,3-2.9,4.7C456.3,333.7,458.3,333.7,460.6,333.7z" />
						<path class="st1" d="M135.5,325.5c-0.7,0.9-1.2,1.7-1.8,2.4c-0.2,0.2-0.7,0.4-1,0.4c-1.6,0-3.2,0-4.8,0c-0.4,0-1,0.1-1.2,0.4
			c-1.1,1.6-2,3.2-3.2,5.1c2.2,0,4.1,0,6.3,0c-0.6,1-0.9,1.8-1.4,2.4c-0.2,0.3-0.7,0.4-1,0.4c-1.6,0-3.2,0-4.8,0.1
			c-0.5,0-1.1,0.4-1.4,0.8c-0.9,1.3-1.8,2.7-2.6,4.1c-0.2,0.3-0.3,0.6-0.6,1.1c2.5,0,4.7,0,7,0c-0.6,2.2-1.3,2.8-3.3,2.8
			c-2.9,0-5.7,0-8.8,0c0.2-0.5,0.4-0.8,0.6-1.1c3.7-6,7.4-12,11.1-18c0.2-0.4,0.8-0.8,1.2-0.8C129,325.4,132.1,325.5,135.5,325.5z" />
						<path class="st1" d="M324.6,326.3c-0.5,0.9-1,1.8-1.4,2.7c-1.7-0.6-3.3-1.4-5.1-0.8c-0.7,0.2-1.4,0.5-2,1c-1.7,1.4-1.7,2.9-0.1,4.4
			c0.3,0.3,0.7,0.7,1,1c1.5,1.6,1.8,3,0.9,5c-1.6,3.4-4.4,5.2-8.1,5.9c-2.3,0.4-4.5,0.1-6.7-1.1c1-0.8,1.9-1.6,2.9-2.4
			c1.5,1.1,3.6,1.2,5.7,0.3c0.6-0.3,1.2-0.6,1.7-1.1c1.4-1.4,1.4-2.9,0-4.3c-0.1-0.1-0.2-0.2-0.3-0.2c-2.7-2.2-3.2-3.6-0.8-7.6
			C314.6,325.4,320.8,324,324.6,326.3z" />
						<path class="st1" d="M153.4,325.5c1.4,0,2.5,0,3.7,0c-0.2,2.9-0.3,5.8-0.5,9.1c0.6-0.5,0.9-0.7,1.1-0.9c2.4-2.4,4.8-4.7,7.1-7.1
			c0.8-0.9,1.7-1.3,2.9-1.2c0.6,0.1,1.2,0,2.1,0c-1.8,1.6-3.3,3.1-4.8,4.5c-2.6,2.4-5.2,4.8-7.8,7.2c-0.4,0.4-0.8,0.9-1.1,1.4
			c-1.2,1.9-2.4,3.8-3.5,5.7c-0.5,0.9-1.1,1.3-2.1,1.2c-0.6-0.1-1.2,0-2,0c1.1-1.9,2-3.6,3.1-5.2c1.3-1.9,1.9-3.9,1.8-6.2
			C153.3,331.3,153.4,328.5,153.4,325.5z" />
						<path class="st1" d="M329.1,345.4c3.6-5.8,7-11.3,10.6-17.2c-2.1,0-4,0-6,0c0.5-0.9,1-1.7,1.4-2.4c0.1-0.2,0.5-0.4,0.7-0.4
			c4.8,0,9.6,0,14.5,0c-0.9,2.2-1.7,3-3.9,2.7c-2.6-0.3-4,0.6-5.3,2.9c-2.4,4.4-5.3,8.5-7.8,12.7c-0.8,1.3-1.6,1.9-3.1,1.7
			C330,345.4,329.7,345.4,329.1,345.4z" />
					</g>
				</svg>

				<!-- <svg width="110" height="60" xmlns="http://www.w3.org/2000/svg">
					<g stroke="null" id="svg_10">
						<g stroke="null" transform="matrix(0.4497006952389148,0,0,0.4497006952389148,-277.9246261602343,-227.49863655548296) " id="svg_15">
							<path stroke="null" id="svg_11" d="m646.278133,614.931746c-4.281,0 -8.069,-0.929 -11.363,-2.785c-3.295,-1.856 -5.912,-4.506 -7.852,-7.952l4.666,-6.02c1.772,2.978 3.914,5.286 6.422,6.924c2.509,1.638 5.2,2.457 8.077,2.457c1.873,0 3.569,-0.367 5.092,-1.104c1.521,-0.735 2.734,-1.797 3.637,-3.186c0.903,-1.387 1.355,-3.035 1.355,-4.941c0,-1.839 -0.46,-3.287 -1.38,-4.34c-0.921,-1.053 -2.275,-1.981 -4.063,-2.784c-1.79,-0.802 -4.023,-1.722 -6.698,-2.76c-2.142,-0.869 -4.215,-1.855 -6.222,-2.959c-2.006,-1.104 -3.654,-2.5 -4.941,-4.189c-1.289,-1.689 -1.932,-3.838 -1.932,-6.447c0,-2.809 0.761,-5.3 2.283,-7.475c1.521,-2.174 3.62,-3.88 6.296,-5.117c2.676,-1.236 5.719,-1.855 9.131,-1.855c2.776,0 5.468,0.551 8.078,1.655c2.609,1.104 5.083,2.693 7.425,4.766l-3.914,5.92c-3.479,-3.679 -7.325,-5.519 -11.54,-5.519c-2.542,0 -4.599,0.645 -6.17,1.932c-1.573,1.288 -2.358,2.952 -2.358,4.991c0,1.572 0.568,2.885 1.706,3.938c1.137,1.055 2.592,1.966 4.365,2.734c1.772,0.77 3.612,1.522 5.519,2.258c2.876,1.071 5.393,2.175 7.55,3.312s3.838,2.601 5.042,4.39c1.205,1.789 1.807,4.189 1.807,7.2c0,3.512 -0.828,6.54 -2.484,9.08c-1.656,2.542 -3.988,4.491 -6.999,5.846c-3.009,1.353 -6.521,2.03 -10.535,2.03z" fill="#87CDE9" />
							<path stroke="null" id="svg_12" d="m682.801133,613.325746l0,-100.114l9.733,0l0,69.11l20.068,-20.37l12.643,0l-23.932,24.483l25.135,26.891l-12.492,0l-21.422,-23.178l0,23.178l-9.733,0z" fill="#87CDE9" />
							<path stroke="null" id="svg_13" d="m745.953133,632.249746l10.546,-21.733l-24.432,-48.564l11.087,0l18.965,38.881l17.007,-38.881l11.087,0l-33.223,70.297l-11.037,0z" fill="#87CDE9" />
							<path stroke="null" id="svg_14" d="m826.587133,614.931746c-5.386,0 -10.218,-1.188 -14.5,-3.563c-4.281,-2.374 -7.659,-5.61 -10.134,-9.708c-2.475,-4.097 -3.712,-8.721 -3.712,-13.872c0,-3.813 0.702,-7.375 2.107,-10.687c1.404,-3.311 3.361,-6.229 5.87,-8.754c2.509,-2.524 5.419,-4.491 8.729,-5.896c3.311,-1.404 6.856,-2.106 10.636,-2.106c5.352,0 10.042,1.103 14.073,3.311c4.03,2.208 7.175,5.286 9.433,9.231c2.257,3.946 3.386,8.528 3.386,13.746c0,0.435 -0.018,0.787 -0.05,1.053c-0.034,0.269 -0.101,0.62 -0.201,1.054l-44.15,0c0,3.58 0.811,6.74 2.434,9.482c1.621,2.743 3.862,4.892 6.723,6.447c2.859,1.555 6.146,2.333 9.857,2.333c3.513,0 6.656,-0.719 9.433,-2.157c2.775,-1.438 5.185,-3.579 7.225,-6.422l7.124,3.713c-2.442,4.047 -5.794,7.191 -10.06,9.431c-4.264,2.243 -9.005,3.364 -14.223,3.364zm-18.011,-33.363l34.116,0c-0.469,-2.641 -1.497,-4.958 -3.085,-6.948c-1.589,-1.989 -3.58,-3.545 -5.971,-4.666c-2.392,-1.12 -5.025,-1.681 -7.901,-1.681c-3.011,0 -5.729,0.544 -8.153,1.631s-4.414,2.625 -5.97,4.615c-1.556,1.991 -2.567,4.34 -3.036,7.049z" fill="#87CDE9" />
						</g>
					</g>
				</svg> -->
			</div>
		</div>

    </up-drawer-header>
    <up-drawer-item [icon]="'account-plus'" (click)="skyeDrawerLink.close()" [routerLink]="['/tasks']">
        Tasks
    </up-drawer-item>

    <up-drawer-group icon="account-group" [hidden]="!(user?.hasModule('employee') && user?.allowAccess('employee'))">
        <!-- <up-drawer-item [icon]="'account-group'" accordion [hidden]="!(user?.allowAccess('employee') || user?.allowAccess('employeeemergencycontact') || user?.allowAccess('employeelicense') || user?.allowAccess('employeetraining') || user?.allowAccess('employeeincident') || user?.allowAccess('gps'))"> -->
        <up-drawer-group-header>Employees</up-drawer-group-header>
        <up-drawer-item [icon]="'account-group'" (click)="skyeDrawerLink.close()" [routerLink]="['employees']">
            <!-- <up-drawer-item [icon]="'account-group'" (click)="skyeDrawerLink.close()" [routerLink]="['employees']" [hidden]="!(user?.allowAccess('employee'))"> -->
            List
        </up-drawer-item>
        <up-drawer-item [icon]="'card-account-phone'" (click)="skyeDrawerLink.close()" [routerLink]="['employees/emergency-contacts/reporting']" [hidden]="!(user?.allowAccess('employeeemergencycontact'))">
            Emergency Contacts
        </up-drawer-item>
        <!-- <up-drawer-item [icon]="'card-account-details'" (click)="skyeDrawerLink.close()" [routerLink]="['employees/licenses/reporting']" [hidden]="!(user?.allowAccess('employeelicense'))">
            Licenses
        </up-drawer-item> -->
        <up-drawer-item [icon]="'account-star'" (click)="skyeDrawerLink.close()" [routerLink]="['employees/trainings/reporting']" [hidden]="!(user?.allowAccess('employeetraining'))">
            Training
        </up-drawer-item>
        <up-drawer-item [icon]="'account-alert'" (click)="skyeDrawerLink.close()" [routerLink]="['employees/incidents/reporting']" [hidden]="!(user?.allowAccess('employeeincident'))">
            Incidents
        </up-drawer-item>
        <!-- <up-drawer-item [icon]="'router-wireless'" (click)="skyeDrawerLink.close()" [routerLink]="['employees/gps-users']"  [hidden]="!(user?.allowAccess('gps'))">
            GPS Users
        </up-drawer-item>
        <up-drawer-item [icon]="'chart-areaspline'" (click)="skyeDrawerLink.close()" [routerLink]="['/drivers/dashboard']"  [hidden]="!(user?.allowAccess('gps'))">
            Driver Safety Dashboard
        </up-drawer-item>
        <up-drawer-item [icon]="'card-account-details-star'" (click)="skyeDrawerLink.close()" [routerLink]="['/drivers']"  [hidden]="!(user?.allowAccess('gps'))">
            Driver Safety Scorecard
        </up-drawer-item> -->
        <up-drawer-item [icon]="'chart-areaspline'" (click)="skyeDrawerLink.close()" [routerLink]="['drivers/dashboard']"  [hidden]="!(user?.allowAccess('gps'))">
            Driver Safety Dashboard
        </up-drawer-item>
        <up-drawer-item [icon]="'card-account-details-star'" (click)="skyeDrawerLink.close()" [routerLink]="['drivers']"  [hidden]="!(user?.allowAccess('gps'))">
            Driver Safety Scorecard
        </up-drawer-item>
    </up-drawer-group>

    <up-drawer-group icon="truck" [hidden]="!(user?.hasModule('vehicle') && user?.allowAccess('vehicle'))">
        <up-drawer-group-header>Vehicles</up-drawer-group-header>
        <up-drawer-item icon="format-list-checkbox" (click)="skyeDrawerLink.close()" [routerLink]="['/vehicles']" [hidden]="!(user?.allowAccess('vehicle'))">
            List
        </up-drawer-item>
        <up-drawer-item icon="file" (click)="skyeDrawerLink.close()" [routerLink]="['vehicles/permits/reporting']" [hidden]="!(user?.allowAccess('vehiclepermit'))">
            Permits
        </up-drawer-item>
        <!-- <up-drawer-item icon="engine" (click)="skyeDrawerLink.close()" [routerLink]="['vehicles/inspections/reporting']" [hidden]="!(user?.allowAccess('vehicleinspection'))">
            Inspections
        </up-drawer-item> -->
        <up-drawer-item icon="engine" (click)="skyeDrawerLink.close()" [routerLink]="['vehicles/servicing/reporting']" [hidden]="!(user?.allowAccess('vehicleservicing'))">
            Servicing
        </up-drawer-item>

    </up-drawer-group>

    <up-drawer-group icon="truck-trailer" [hidden]="!(user?.hasModule('trailer') && user?.allowAccess('trailer'))">
        <up-drawer-group-header>Trailers</up-drawer-group-header>
        <up-drawer-item [icon]="'format-list-checkbox'" (click)="skyeDrawerLink.close()" [routerLink]="['/trailers']" [hidden]="!(user?.allowAccess('trailer'))">
            List
        </up-drawer-item>
        <up-drawer-item icon="file" (click)="skyeDrawerLink.close()" [routerLink]="['trailers/permits/reporting']" [hidden]="!(user?.allowAccess('trailerpermit'))">
            Permits
        </up-drawer-item>
        <up-drawer-item icon="engine" (click)="skyeDrawerLink.close()" [routerLink]="['trailers/inspections/reporting']" [hidden]="!(user?.allowAccess('trailerinspection'))">
            Inspections
        </up-drawer-item>
        <up-drawer-item icon="engine" (click)="skyeDrawerLink.close()" [routerLink]="['trailers/servicing/reporting']" [hidden]="!(user?.allowAccess('trailerservicing'))">
            Servicing
        </up-drawer-item>

    </up-drawer-group>

    <up-drawer-group icon="truck" [hidden]="!(user?.hasModule('equipment') && user?.allowAccess('equipment'))">
        <up-drawer-group-header>Equipment</up-drawer-group-header>
        <up-drawer-item [icon]="'format-list-checkbox'" (click)="skyeDrawerLink.close()" [routerLink]="['/equipment']" [hidden]="!(user?.allowAccess('equipment'))">
            List
        </up-drawer-item>
        <!-- <up-drawer-item [icon]="'file'" (click)="skyeDrawerLink.close()" [routerLink]="['equipment-reporting/permits']" [hidden]="!(user?.allowAccess('equipmentpermit'))">
            Permits
        </up-drawer-item> -->
        <up-drawer-item [icon]="'engine'" (click)="skyeDrawerLink.close()" [routerLink]="['equipment/inspections']" [hidden]="!(user?.allowAccess('equipmentinspection'))">
            Inspections
        </up-drawer-item>
        <up-drawer-item [icon]="'engine'" (click)="skyeDrawerLink.close()" [routerLink]="['equipment/servicing']" [hidden]="!(user?.allowAccess('equipmentservicing'))">
            Servicing
        </up-drawer-item>

    </up-drawer-group>

    <!-- <up-drawer-group icon="train-car-container" [hidden]="!(user?.allowAccess('container'))">
        <up-drawer-group-header>Vault Tracking</up-drawer-group-header>
        <up-drawer-item [icon]="'train-car-container'" (click)="skyeDrawerLink.close()" [routerLink]="['/vault']" [hidden]="!(user?.allowAccess('container'))">
            Vaults
        </up-drawer-item>
        <up-drawer-item [icon]="'warehouse'" (click)="skyeDrawerLink.close()" [routerLink]="['/warehouse']" [hidden]="!(user?.allowAccess('warehouse'))">
            Warehouses
        </up-drawer-item>
        <up-drawer-item [icon]="'engine'" (click)="skyeDrawerLink.close()" [routerLink]="['container-reporting/servicing']" [hidden]="!(user?.allowAccess('containerservicing'))">
            Vault Servicing
        </up-drawer-item>

    </up-drawer-group>

    <up-drawer-item icon="server" (click)="skyeDrawerLink.close()" [routerLink]="['/skye-drive']" [hidden]="!(user?.allowAccess('skyedrive'))">
        Skye Drive
    </up-drawer-item> -->

	<up-drawer-group icon="shield-car" [hidden]="!(user?.allowAccess('collision') || user?.allowAccess('claim') )">
        <up-drawer-group-header>Collisions</up-drawer-group-header>
        <up-drawer-item icon="shield-car" (click)="skyeDrawerLink.close()" [routerLink]="['/collisions']" [hidden]="!(user?.allowAccess('collision'))">
			Collisions
		</up-drawer-item>
        <up-drawer-item [icon]="'checkbook'" (click)="skyeDrawerLink.close()" [routerLink]="['/claims']" [hidden]="!(user?.allowAccess('claim'))">
            Claims
        </up-drawer-item>
    </up-drawer-group>

    

    <up-app-bar #appBar type="standard" navigationIcon="menu" [navigating]="isNavigating" (navigationIconClicked)="skyeDrawerLink.toggle()">
        <up-app-bar-actions>
            <span class="show-gt-md">
                <up-button icon="settings" up-menu [menu]="adminMenu" *ngIf="user.allowAccess('branch') ||

					user.allowAccess('claimtype') ||
					user.allowAccess('claimcosttype') ||
					user.allowAccess('branch') ||

                    user.allowAccess('collisionactivitytype') ||
                    user.allowAccess('collisioncontroldevice') ||
                    user.allowAccess('collisiontype') ||
                    user.allowAccess('collisioncosttype') ||
                    user.allowAccess('collisionlocation') ||
                    user.allowAccess('collisionroadcondition') ||
                    user.allowAccess('collisionroadtype') ||
                    user.allowAccess('collisiontasktype') ||
                    user.allowAccess('collisiontraffictype') ||
                    user.allowAccess('collisionvisibilitytype') ||
                    user.allowAccess('collisionweathercondition') ||
                    user.allowAccess('containerlocation') ||
                    user.allowAccess('employeecoveragetype') ||
                    user.allowAccess('employeedepartment') ||
                    user.allowAccess('employeetype') ||
                    user.allowAccess('employeeform') ||
                    user.allowAccess('employeeincidenttype') ||
                    user.allowAccess('employeelanguage') ||
                    user.allowAccess('employeeposition') ||
                    user.allowAccess('employeestatustype') ||
                    user.allowAccess('employeetrainingtype') ||
                    user.allowAccess('permittype') ||
                    user.allowAccess('movelocation') ||
                    user.allowAccess('vehicledispatchtype') ||
                    user.allowAccess('trailerdispatchtype') ||
                    user.allowAccess('serviceprovider') ||
                    user.allowAccess('servicetype') ||
                    user.allowAccess('trailerform') ||
                    user.allowAccess('trailertype') ||
                    user.allowAccess('user') ||
                    user.allowAccess('vehicleform') ||
                    user.allowAccess('vehicledispatchline') ||
                    user.allowAccess('vehicletype')">
                    Admin
                </up-button>
                <up-menu #adminMenu scrollOverflow>
                    <up-menu-item *ngIf="user.allowAccess('branch')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/branch']">Branches</up-menu-item>                    
					<up-menu-item *ngIf="user.allowAccess('branch')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/branchadmin']">Branch Admin</up-menu-item>

                    <up-menu-item *ngIf="user?.hasModule('vehicle') && user.allowAccess('collisionactivitytype') || user.allowAccess('collisioncontroldevice') || user.allowAccess('collisiontype') || user.allowAccess('collisioncosttype') || user.allowAccess('collisionlocation') || user.allowAccess('collisionroadcondition') || user.allowAccess('collisionroadtype') || user.allowAccess('collisiontasktype') || user.allowAccess('collisiontraffictype') || user.allowAccess('collisionvisibilitytype') || user.allowAccess('collisionweathercondition')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['admin/collision']">Collision Admin</up-menu-item>
                    <up-menu-item *ngIf="user?.hasModule('vehicle') && user.allowAccess('claimtype') || user.allowAccess('claimcosttype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['admin/claim']">Claim Admin</up-menu-item>
                    <up-menu-item *ngIf="user.admin" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/company']">Company Settings</up-menu-item>
                    <!-- <up-menu-item *ngIf="user.allowAccess('containerlocation')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/vault']">Vault Admin</up-menu-item> -->
                    <up-menu-item *ngIf="user?.hasModule('employee') && user.allowAccess('employeedisabilitycoveragetype') || user.allowAccess('employeedepartment') || user.allowAccess('employeetype') || user.allowAccess('employeeform') || user.allowAccess('employeeincidenttype') || user.allowAccess('employeelanguage') || user.allowAccess('employeeposition') || user.allowAccess('employeestatustype') || user.allowAccess('employeetrainingtype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['admin/employee']">Employee Admin</up-menu-item>
                    <up-menu-item *ngIf="user?.hasModule('equipment') && user.allowAccess('equipmentdispatchline') || user.allowAccess('equipmentform') || user.allowAccess('equipmenttype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/equipment']">Equipment Admin</up-menu-item>
<!--                     <up-menu-item *ngIf="user.hasModule('moving') && (user.allowAccess('movelocation') || user.allowAccess('employeedispatchtype') || user.allowAccess('vehicledispatchtype') || user.allowAccess('trailerdispatchtype'))" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/scheduling']">Scheduling Admin</up-menu-item>
 -->                    <up-menu-item *ngIf="user.allowAccess('securityGroup')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/security-group']">Security Groups</up-menu-item>
                    <up-menu-item *ngIf="user.hasModule('trailer') && user.allowAccess('trailerform') || user.allowAccess('trailertype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/trailer']">Trailer Admin</up-menu-item>
                    <up-menu-item *ngIf="user.allowAccess('user')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/user']">Users</up-menu-item>
                    <up-menu-item *ngIf="user?.hasModule('vehicle') && user.allowAccess('vehicledispatchline') || user.allowAccess('vehicleform') || user.allowAccess('vehicletype') || user.allowAccess('permittype') || user.allowAccess('serviceprovider') || user.allowAccess('servicetype') || user.allowAccess('enginetype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/vehicle']">Vehicle Admin</up-menu-item>
                </up-menu>

                <up-button icon="account" up-menu [menu]="userMenu">
                    {{ user.claimValue("firstName") }}
                </up-button>
                <up-menu #userMenu>
                    <!-- <up-menu-item hasRouterLink icon="account-details" (click)="skyeDrawerLink.close()" [routerLink]="['/account-details']">Account Details</up-menu-item> -->
                    <up-menu-item hasRouterLink icon="account-key" (click)="changePassword()">Change Password</up-menu-item>
                    <up-menu-item hasRouterLink icon="monitor" (click)="changeStartupScreen()">Change Startup Screen</up-menu-item>
                    <!-- <up-menu-item hasRouterLink icon="account-settings" (click)="skyeDrawerLink.close()" [routerLink]="['/user-settings']">User Settings</up-menu-item> -->
                    <up-menu-item hasRouterLink divider></up-menu-item>
                    <up-menu-item hasRouterLink icon="logout" (click)="logout()">Logout</up-menu-item>
                </up-menu>
            </span>


            <span class="show-lt-md">
                <up-button icon="settings" up-menu [menu]="adminMenuMobile" *ngIf="user.admin || user.allowAccess('branch') || user.allowAccess('branch') || user.allowAccess('employeecoveragetype') || user.allowAccess('employeedepartment') || user.allowAccess('employeetype') || user.allowAccess('employeeform') || user.allowAccess('employeeincidenttype') || user.allowAccess('employeelanguage') || user.allowAccess('employeeposition') || user.allowAccess('employeestatustype') || user.allowAccess('employeetrainingtype') || user.allowAccess('permittype') || user.allowAccess('movelocation') || user.allowAccess('employeedispatchtype') || user.allowAccess('vehicledispatchtype') || user.allowAccess('trailerdispatchtype') || user.allowAccess('serviceprovider') || user.allowAccess('servicetype') || user.allowAccess('trailerform') || user.allowAccess('trailertype') || user.allowAccess('user') || user.allowAccess('vehicleform') || user.allowAccess('vehicledispatchline') || user.allowAccess('vehicletype')"></up-button>
                <up-button icon="cog" up-menu [menu]="adminMenuMobile" *ngIf="user.admin || user.allowAccess('branch') || user.allowAccess('employeecoveragetype') || user.allowAccess('employeedepartment') || user.allowAccess('employeetype') || user.allowAccess('employeeform') || user.allowAccess('employeeincidenttype') || user.allowAccess('employeelanguage') || user.allowAccess('employeeposition') || user.allowAccess('employeestatustype') || user.allowAccess('employeetrainingtype') || user.allowAccess('permittype') || user.allowAccess('movelocation') || user.allowAccess('employeedispatchtype') || user.allowAccess('vehicledispatchtype') || user.allowAccess('trailerdispatchtype') || user.allowAccess('serviceprovider') || user.allowAccess('servicetype') || user.allowAccess('trailerform') || user.allowAccess('trailertype') || user.allowAccess('user') || user.allowAccess('vehicleform') || user.allowAccess('vehicledispatchline') || user.allowAccess('vehicletype')"  style="margin-top:6px;"></up-button>
                <up-menu #adminMenuMobile>
                    <up-menu-item *ngIf="user.allowAccess('branch')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/branch']">Branches</up-menu-item>
					<up-menu-item *ngIf="user.allowAccess('branch')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/branchadmin']">Branch Admin</up-menu-item>

                    <up-menu-item *ngIf="user?.hasModule('vehicle') && user.allowAccess('collisionactivitytype') || user.allowAccess('collisioncontroldevice') || user.allowAccess('collisiontype') || user.allowAccess('collisioncosttype') || user.allowAccess('collisionlocation') || user.allowAccess('collisionroadcondition') || user.allowAccess('collisionroadtype') || user.allowAccess('collisiontasktype') || user.allowAccess('collisiontraffictype') || user.allowAccess('collisionvisibilitytype') || user.allowAccess('collisionweathercondition')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['admin/collision']">Collision Admin</up-menu-item>
                    <up-menu-item *ngIf="user.admin" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/company']">Company Settings</up-menu-item>
                    <up-menu-item *ngIf="user?.hasModule('employee') && user.allowAccess('employeedisabilitycoveragetype') || user.allowAccess('employeedepartment') || user.allowAccess('employeetype') || user.allowAccess('employeeform') || user.allowAccess('employeeincidenttype') || user.allowAccess('employeelanguage') || user.allowAccess('employeeposition') || user.allowAccess('employeestatustype') || user.allowAccess('employeetrainingtype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/employee']">Employee Admin</up-menu-item>
                    <up-menu-item *ngIf="user?.hasModule('equipment') && user.allowAccess('equipmentdispatchline') || user.allowAccess('equipmentform') || user.allowAccess('equipmenttype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/equipment']">Equipment Admin</up-menu-item>
<!--                     <up-menu-item *ngIf="user.allowAccess('movelocation') || user.allowAccess('employeedispatchtype') || user.allowAccess('vehicledispatchtype') || user.allowAccess('trailerdispatchtype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/scheduling']">Scheduling Admin</up-menu-item>
 -->                    <up-menu-item *ngIf="user.allowAccess('securityGroup')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/security-group']">Security Groups</up-menu-item>
                    <up-menu-item *ngIf="user?.hasModule('trailer') && user.allowAccess('trailerform') || user.allowAccess('trailertype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/trailer']">Trailer Admin</up-menu-item>
                    <up-menu-item *ngIf="user.allowAccess('user')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/user']">Users</up-menu-item>
                    <up-menu-item *ngIf="user?.hasModule('vehicle') && user.allowAccess('vehicledispatchline') || user.allowAccess('vehicleform') || user.allowAccess('vehicletype')" hasRouterLink (click)="skyeDrawerLink.close()" [routerLink]="['/admin/vehicle']">Vehicle Admin</up-menu-item>
                </up-menu>
                <up-button icon="account" up-menu [menu]="userMenuMobile" style="margin-top:6px;"></up-button>
                <up-menu #userMenuMobile>
                    <!-- <up-menu-item hasRouterLink icon="account-details" (click)="skyeDrawerLink.close()" [routerLink]="['/account-details']">Account Details</up-menu-item> -->
                    <up-menu-item hasRouterLink icon="account-key" (click)="changePassword()">Change Password</up-menu-item>
                    <up-menu-item hasRouterLink icon="monitor" (click)="changeStartupScreen()">Change Startup Screen</up-menu-item>
                    <!-- <up-menu-item hasRouterLink icon="account-settings" (click)="skyeDrawerLink.close()" [routerLink]="['/user-settings']">User Settings</up-menu-item> -->
                    <up-menu-item hasRouterLink divider></up-menu-item>
                    <up-menu-item hasRouterLink icon="logout" (click)="logout()">Logout</up-menu-item>
                </up-menu>
            </span>
        </up-app-bar-actions>
    </up-app-bar>
    <up-drawer-content [@slideInOut]="triggerAnimation(outlet)" [hidden]="isNavigating">
        <router-outlet #outlet="outlet"></router-outlet>
    </up-drawer-content>

	<div *ngIf="phoneDialog">
		PHONE DIALOG
	</div>
</up-drawer>