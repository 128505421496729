import { EventEmitter, Injectable } from '@angular/core';
import { AppUserAuth } from './app-user-auth';
import { AppUser } from './app-user';
import { Router, ActivatedRoute } from '@angular/router';
import { SharedService } from '../shared.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { UserService } from '../../admin/user/user.service';
import { Location } from '@angular/common';

import Amplify, { Auth } from 'aws-amplify';
import { UserBaseModel } from 'src/app/admin/user/user-models';
import { SkyeDrivePostModel } from 'src/app/skye-drive/skye-drive-models';
import { SkyeDriveService } from 'src/app/skye-drive/skye-drive.service';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { PublicKeyCredentialCreationOptionsJSON, PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/types';
import { Http } from 'aws-sdk/clients/xray';
import { Observable } from 'rxjs';
import { HttpResponse } from 'aws-sdk';

@Injectable()
export class SecurityService {
	user: AppUserAuth = new AppUserAuth();
	userModel: UserBaseModel;
	hasFilledAuthData = false;
	isUserModelReady = false;

	public userModelReady = new EventEmitter<UserBaseModel>();
	public mfaRequiredEvent = new EventEmitter<any>();

	public showPhoneDialogEvent = new EventEmitter<boolean>();

	constructor(private location: Location, private http: HttpClient, private skyeDriveService: SkyeDriveService, private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, public sharedService: SharedService) {

		Amplify.configure({
			Auth: {
				identityPoolId: sharedService.cognitoIdentityPoolId,
				region: 'us-east-1',
				userPoolId: sharedService.cognitoUserPoolId,
				userPoolWebClientId: sharedService.cognitoUserPoolClientId,
				mandatorySignIn: false,

				// OPTIONAL - Configuration for cookie storage
				// Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
				/*
				cookieStorage: {
					// REQUIRED - Cookie domain (only required if cookieStorage is provided)
					domain: 'http://localhost:4004',
					// OPTIONAL - Cookie path
					path: '/',
					// OPTIONAL - Cookie expiration in days
					expires: 365,
					// OPTIONAL - Cookie secure flag
					// Either true or false, indicating if the cookie transmission requires a secure protocol (https).
					secure: true
				},*/

				/*
				// OPTIONAL - Hosted UI configuration
				 oauth: {
					domain: 'your_cognito_domain',
					scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
					redirectSignIn: 'http://localhost:3000/',
					redirectSignOut: 'http://localhost:3000/',
					responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
				}
				*/
			}
		});

		// You can get the current config object
		//const currentConfig = Auth.configure();

		this.fillAuthData();
	}

	async login(entity: AppUser): Promise<AppUserAuth> {
		return new Promise(async (resolve, reject) => {
			try {

				


				await Auth.signIn(entity.userName, entity.password).then(signInUser => {
					console.log("signInUser: ", signInUser);
					if (signInUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
						reject({ challenge: 'NEW_PASSWORD_REQUIRED', signInUser: signInUser })
					}
					Auth.currentSession().then(async res => {
						console.log("res: ", res);
						localStorage.setItem('bearerToken', res.getIdToken().getJwtToken());
						const userIdToken = res.getIdToken().decodePayload();
						console.log("userIdToken: ", userIdToken)
						const userId = userIdToken.userId ? parseInt(userIdToken.userId) : null
						const userData = userIdToken.userData ? userIdToken.userData : null
						const userName = userIdToken.email
						const companyId = userIdToken.companyId
						localStorage.setItem('companyId', companyId);
						console.log("--------- set companyId: ",  companyId);
						const permissions = await this.getUserPermissions(userId);
						const userResponse = await this.getUser(userId);
						console.log("userResponse: ", userResponse);
						try {
							console.log("setUser 2: ", userData);

							await this.setUser(userResponse);
							//await this.getUser(userId);
							//await this.updateUserLoginTime(userId);
							localStorage.setItem('loggedInUserId', JSON.stringify(userId));
							console.log("userIdToken: ", userIdToken);
							const newUser = new AppUserAuth(userIdToken);
							newUser.isAuthenticated = true;
							Object.assign(this.user, newUser);
							localStorage.setItem('authorizationData', JSON.stringify(newUser));


							console.log("loadLogo()")
							const signUpLogoDataRaw = localStorage.getItem('signUpLogo');

							console.log("has signUpLogoDataRaw");

							if (signUpLogoDataRaw) {
								const signUpLogoData = JSON.parse(signUpLogoDataRaw);
								console.log("signUpLogoData: ", signUpLogoData);

								// Convert Base64 back to a Blob
								const fileBlob = this.base64ToBlob(signUpLogoData.fileValue);
								const fileObject = new File([fileBlob], "uploaded_logo.png", { type: fileBlob.type });

								// Ensure postLogo receives a proper File object
								await this.postLogo(signUpLogoData.branchId, signUpLogoData.skyeDriveParentId, fileObject);
							}

							// 2FA
							// Biometrics
							/* const storedDevice = localStorage.getItem('registeredDevice');
							console.log("storedDevice: ", storedDevice);
							if (!storedDevice) {
								console.log("registerBiometricDevice()")
								this.registerBiometricDevice(userId, userName)
							} else {
								console.log("Attempting biometric login...");
								const biometricSuccess = await this.tryBiometricLogin(storedDevice);
								if (biometricSuccess) {
									console.log("Biometric authentication successful! Skipping 2FA.");

									resolve(newUser);
								} else if (userIdToken.phone_number_verified) {
									console.log("handle biometric failure")
									try {
										console.log("Requesting SMS MFA code...: ", userIdToken);

										// Request Cognito to send the MFA code via SMS
										this.postGenerateMFA(userIdToken.phone_number, userIdToken.userId).subscribe(
											resp => {
												console.log("SMS MFA code sent successfully.");
												this.mfaRequiredEvent.emit({ mfaUserId: userIdToken.userId });
												setTimeout(() => {
													resolve(newUser);
												});
											},
											error => {
												console.log("SMS MFA api error: ", error);
												resolve(newUser);
											}
										)
									} catch (error) {
										console.error("Error requesting SMS MFA:", error);
										reject("mfa failure")
									}
								} else {
									resolve(newUser);
								}
							} */


							resolve(newUser);

						} catch (error) {
							console.log
							reject(error);
						}
					}).catch(error => {
						reject(error);
					});
				}).catch(e => {
					console.log('Auth sign in error: ', e);
					if (e.code == 'PasswordResetRequiredException') {
						Auth.forgotPassword(entity.userName);
					} else if (e.code == 'NotAuthorizedException' || e.code == 'NotAuthorizedException: Incorrect username or password.') {
						console.log("do forgot password")
						Auth.forgotPassword(entity.userName).then(data => {
							console.log("forgot data: ", data)
						}).catch(err => {
							console.log("forgot error: ", err);
						});
					}
					reject(e);
				});
			} catch (error) {
				reject();
			}
		});
	}

	

	base64ToBlob(base64: string): Blob {
		const byteCharacters = atob(base64.split(',')[1]); // Decode Base64
		const byteNumbers = new Array(byteCharacters.length);

		for (let i = 0; i < byteCharacters.length; i++) {
			byteNumbers[i] = byteCharacters.charCodeAt(i);
		}

		const byteArray = new Uint8Array(byteNumbers);
		const mimeType = base64.match(/^data:(.*);base64,/)[1]; // Extract MIME type

		return new Blob([byteArray], { type: mimeType });
	}

	postLogo(branchId, parentId, fileValue): Promise<any> {
		console.log("postLogo: ", branchId);
		return new Promise((resolve, reject) => {
			// post:
			console.log("sdu post:")
			var postObject: SkyeDrivePostModel = {
				description: 's3Logo',
				parentId: parentId,
				isFolder: false,
				file: fileValue,
				mutable: true,
				associatedType: 's3Logo'
			}
			console.log("post object: ", postObject);
			this.skyeDriveService.post(postObject).subscribe(
				resp => {
					if (resp.type === HttpEventType.Response) {
						localStorage.removeItem('signUpLogo')
						resolve(true);
					}
				}, err => {
					console.log("err: ", err);
				}
			);
			resolve(true);
		})
	}




	getUserPermissions(userId): Promise<any> {
		return new Promise((resolve, reject) => {
			this.userService.getPermissions(userId).subscribe(
				resp => {
					console.log("userPermissions resp: ", resp);
					resolve(resp.body);
				},
				error => {
					reject();
				}
			)
		});
	}

	resendCode(username): Promise<any> {
		return new Promise((resolve, reject) => {
			Auth.forgotPassword(username).then(
				resp => {
					resolve(resp);
				}
			).catch(
				error => {
					reject(error);
				}
			);
		});
	}

	setPassword(user, password): Promise<any> {
		return new Promise((resolve, reject) => {

			Auth.completeNewPassword(user, password, []).then(
				resp => {
					console.log("resp: ", resp);
					Auth.currentSession().then(async res => {
						localStorage.setItem('bearerToken', res.getIdToken().getJwtToken());
						const userIdToken = res.getIdToken().decodePayload();
						const userId = userIdToken.userId ? parseInt(userIdToken.userId) : null
						const userData = userIdToken.userData ? userIdToken.userData : null
						const permissions = await this.getUserPermissions(userId);

						const userResponse = await this.getUser(userId);
						console.log("userResponse: ", userResponse);
						try {
							console.log("setUser 3: ", userData);

							await this.setUser(userResponse);
							//await this.getUser(userId);
							//await this.updateUserLoginTime(userId);
							localStorage.setItem('loggedInUserId', JSON.stringify(userId));
							const newUser = new AppUserAuth(userIdToken);
							newUser.isAuthenticated = true;
							Object.assign(this.user, newUser);
							localStorage.setItem('authorizationData', JSON.stringify(newUser));
							resolve(newUser);
						} catch (error) {
							console.log
							reject(error);
						}
					}).catch(error => {
						console.log("setPassword error: ", error)
						reject(error);
					});
				}
			).catch(
				error => {
					console.log("setPassword error2: ", error)
					reject(error);
				}
			);

		});
	}

	changePassword(username, oldPassword, newPassword): Promise<any> {
		return new Promise((resolve, reject) => {
			Auth.currentAuthenticatedUser()
				.then(user => {
					Auth.changePassword(user, oldPassword, newPassword).then(
						resp => {
							resolve(resp);
						}
					).catch(
						error => {
							reject(error);
						}
					);
				}).catch(
					error => {
						reject(error);
					}
				)
		});
	}

	submitForgotPassword(username, password, code): Promise<any> {
		return new Promise((resolve, reject) => {
			Auth.forgotPasswordSubmit(username, code, password).then(
				resp => {
					resolve(resp);
				}
			).catch(
				error => {
					reject(error);
				}
			);
		});
	}

	updateUserLoginTime(id): Promise<any> {
		return new Promise((resolve, reject) => {
			const patchDoc = [{ 'op': 'replace', 'path': '/' + 'lastLoginTime', 'value': new Date() }];
			this.userService.patch(this.sharedService.adminApiUrl + '/users/' + id, patchDoc).subscribe(
				resp => {
					resolve(true);
				},
				error => {
					reject(error);
				});

		})
	}

	updateUserLogoutTime(id): Promise<any> {
		return new Promise((resolve, reject) => {
			const patchDoc = [{ 'op': 'replace', 'path': '/' + 'lastLogoutTime', 'value': new Date() }];
			this.userService.patch(this.sharedService.adminApiUrl + '/users/' + id, patchDoc).subscribe(
				resp => {
					console.log("patch resp: ", resp);
					resolve(true);
				},
				error => {
					reject(error);
				});

		})
	}

	setMFAReady() {
		this.userModelReady.emit(this.user.userModel);
	}

	setUser(userData) {
		return new Promise((resolve, reject) => {
			this.userModel = JSON.parse(JSON.stringify(userData));
			this.user.userModel = JSON.parse(JSON.stringify(this.userModel));
			//console.log("user.userModel: ", this.user.userModel)
			//console.log("security set user: ", this.user);
			//localStorage.setItem('companyLogo', this.user.claimValue('logoUrl'));
			//localStorage.setItem('companyId', this.user.claimValue('companyId'));
			//console.log("--------- set companyId: ",  this.user.claimValue('companyId'));
			this.isUserModelReady = true;
			setTimeout(() => {
				console.log("userModel: ", this.userModel)
				this.userModelReady.emit(this.user.userModel);
			});

			resolve(this.userModel);
		})
	}

	showPhoneDialog() {
		
		console.log("=========== security showPhoneDialog")
		this.showPhoneDialogEvent.emit(true);
	}

	public getUser(id): Promise<any> {
		return new Promise((resolve, reject) => {
			this.userService.get(id).subscribe(
				resp => {
					this.userModel = resp.body;
					this.user.userModel = JSON.parse(JSON.stringify(this.userModel));
					this.isUserModelReady = true;
					this.userModelReady.emit(this.user.userModel);
					resolve(this.userModel);
				},
				error => {
					reject(error);
				});

		})
	}

	async logout() {
		const userId = JSON.parse(localStorage.getItem('loggedInUserId'))
		if (userId) {
			// await this.updateUserLogoutTime(this.user.userModel.userId)
		}
		this.resetUser();
		this.router.navigate(['/login']);
	}

	resetUser(): void {
		this.user.userName = '';
		this.user.bearerToken = '';
		this.user.isAuthenticated = false;
		this.user.claims = [];
		localStorage.removeItem('bearerToken');
		localStorage.removeItem('authorizationData');
	}

	fillAuthData(): Promise<any> {
		return new Promise((resolve, reject) => {

			// load the user data if we have one stored.
			const helper = new JwtHelperService();
			const token = localStorage.getItem('bearerToken');
			if (token) {
				const isExpired = helper.isTokenExpired(token);
				if (isExpired) {
					this.logout();
					reject()
				} else {
					const authData = localStorage.getItem('authorizationData');
					if (authData) {
						const u = JSON.parse(authData);
						Object.assign(this.user, u);
						setTimeout(async () => {
							if (!this.hasFilledAuthData && this.user.claimValue('userId') !== undefined) {
								this.hasFilledAuthData = true;

								const permissions = await this.getUserPermissions(this.user.claimValue('userId'));

								const userResponse = await this.getUser(this.user.claimValue('userId'));
								console.log("userResponse: ", userResponse);
								console.log("setUser 1: ", this.user.claimValue('userData'));
								await this.setUser(userResponse)
								//await this.getUser(this.user.claimValue('userId'));
								resolve(true);
							}
						});
					} else {
						this.logout();
						reject(false)
					}
				}
			} else {
				if (this.location.path() == '/sign-up') {
					return;
				} else if (this.location.path().includes('/login/forgot-password')) {
					return;
				} else {
					this.logout();
					return;
				}
			}
		});
	}
}
