<up-dialog width="500">
    <up-dialog-header>Phone Number</up-dialog-header>
    <up-dialog-body>
        <up-loader spinner *ngIf="!formReady"></up-loader>
        <span *ngIf="formReady">
			<p>A valid phone number is required for Two-Factor Authentication and for important notifications.</p>
            <form #form="ngForm" novalidate id="ngForm" autocomplete="off" (ngSubmit)="save()" [formGroup]="detailForm">
                <button type="submit" style="display:none">Submit</button>
                <up-input formControlName="phoneNumber" [formData]="detailForm.get('phoneNumber')" label="Phone Number" phoneMask></up-input>
                
            </form>
        </span>

    </up-dialog-body>
    <up-dialog-footer>
        <span>
            <up-button (click)="onClose()">Cancel</up-button>
            <up-button (click)="save()" [disabled]="!formReady || !detailForm.valid" filled>Save</up-button>
        </span>
    </up-dialog-footer>
</up-dialog>