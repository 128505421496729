import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICachedObject, ListCacheUpdate, NewCacheService } from 'src/app/shared/cache/new-cache.service';
import { SharedService } from 'src/app/shared/shared.service';
import { SkyeDriveList, SkyeDriveModel, SkyeDrivePostModel } from './skye-drive-models';
import saveAs from 'file-saver';
import { filter, switchMap, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class SkyeDriveService {
	url = this.sharedService.formsApiUrl + 'skyedrives';
	cache: ICachedObject;

	constructor(private http: HttpClient,
		private cacheService: NewCacheService,
		public sharedService: SharedService) {
		this.cache = new ICachedObject;
		this.cache.cacheKey = 'listcache-skyedrives';
		this.cache.listId = 'formId';
		this.cache.getFn = this.getList.bind(this);
	}

	getList(params?: any): Observable<HttpResponse<SkyeDriveList>> {
		return this.http.get<SkyeDriveList>(params != undefined && Object.keys(params).length != 0 ? this.url + '?' + this.sharedService.param(params) : this.url, { observe: 'response' });
	}

	getCachedList(): Promise<SkyeDriveModel[]> {
		return this.cacheService.checkCachedList(this.cache);
	}

	checkDuplicate(field: string, duplicate: string, idValue?: number): Observable<HttpResponse<any>> {
		const params = {
			fieldName: field,
			fieldValue: duplicate,
		}
		if (idValue) {
			params[this.cache.listId] = idValue
		}
		return this.http.get<any>(this.url + '/exists/exists' + '?' + this.sharedService.param(params), { observe: 'response' });
	}

	get(id: number): Observable<HttpResponse<SkyeDriveModel>> {
		return this.http.get<SkyeDriveModel>(this.url + '/' + id, { observe: 'response' });
	}

	getByAssociated(associatedId: number, associatedType: string): Observable<HttpResponse<SkyeDriveList>> {
		var params = { associatedId: associatedId, associatedType: `E^${associatedType}` }
		return this.http.get<SkyeDriveList>(this.url + '?' + this.sharedService.param(params), { observe: 'response' });
	}

	getByAssociatedParent(parentId: number, associatedType: string): Observable<HttpResponse<SkyeDriveList>> {
		var params = { parentId: parentId, associatedType: `E^${associatedType}` }
		return this.http.get<SkyeDriveList>(this.url + '?' + this.sharedService.param(params), { observe: 'response' });
	}

	downloadFile(id, customFilename?: string): Promise<boolean> {
		return new Promise(resolve => {
			this.getFile(id).subscribe(
				async resp => {
					console.log("file: ", resp);


					const filename = resp.body.fileName;  // filename is a field in the response now
					const url = resp.body.url;  // URL is directly available in the response
					const splitURL = url.split('?')[0].split('/');
					const fileNameWithExtension = splitURL[splitURL.length - 1];
					var fileExtension = fileNameWithExtension.split('.')[1];
					console.log("fileExtension: ", fileExtension)
					if (fileExtension == 'bin') {
						fileExtension = 'msg'
					}
					try {
						const fileResponse = await fetch(url);  // Fetch the file from the URL
						console.log("fileResponse")
						const blob = await fileResponse.blob();  // Get the file as a Blob object
						console.log("blob")
						saveAs(blob, customFilename ? customFilename + '.' + fileExtension : filename + '.' + fileExtension);  // Use the saveAs function as before
						console.log("saveAs")
						resolve(true);
					} catch (error) {
						console.log("error: ", error);
						resolve(false);
					}

					/* const filename = resp.headers.get('x-filename');
					const ctype = resp.headers.get('content-type');
					
					const blob = new Blob([resp.body], { type: ctype });
					saveAs(blob, customFilename ? customFilename : filename);
					resolve(true); */
				},
				err => {
					console.log("err: ", err);
					resolve(true);
				}
			);

		})
	}

	getFile(id) {
		return this.http.get<any>(this.sharedService.formsApiUrl + 'skyedrivefiles?id=' + id, { observe: 'response' });
	}

	//@ListCacheUpdate()
	post(skyeDrive: SkyeDrivePostModel): Observable<HttpEvent<SkyeDriveModel>> {
		console.log("post SKyeDrive: ", skyeDrive)
		const formData = new FormData();
		//formData.append('file', skyeDrive.file);
		formData.append('filename', skyeDrive.file.name);
		formData.append('filetype', skyeDrive.file.type);
		formData.append('contentType', skyeDrive.file.type);
		formData.append('associatedId', skyeDrive.associatedId ? skyeDrive.associatedId.toString() : null);
		formData.append('associatedType', skyeDrive.associatedType ? skyeDrive.associatedType : null);
		formData.append('description', skyeDrive.description);
		formData.append('parentId', skyeDrive.parentId ? skyeDrive.parentId.toString() : null);
		formData.append('mutable', skyeDrive.mutable ? 'true' : 'false');
		formData.append('isFolder', skyeDrive.isFolder ? 'true' : 'false');
		formData.append('partialFileKey', skyeDrive.partialFileKey);
		if (skyeDrive.file) {
			formData.append('fileSize', skyeDrive.file.size);
		}
		if (skyeDrive.parentId) {
			formData.append('parentId', <any>skyeDrive.parentId);
		}
		/* if (skyeDrive.skyeFormId) {
			formData.append('skyeFormId', <any>skyeDrive.skyeFormId);
		} */
		return this.http.post<{ preSignedUrl: string }>(this.url, formData, { reportProgress: true, observe: 'events' }).pipe(
			filter((event: HttpEvent<any>) => event instanceof HttpResponse),
			switchMap((response: HttpResponse<{ preSignedUrl: string }>) => {
				const file: File = skyeDrive.file;
				const uploadOptions = {
					headers: new HttpHeaders({
						'Content-Type': file.type,
					}),
					reportProgress: true,
					observe: 'events' as const,
				};
		
				return this.http.put(response.body.preSignedUrl, file, uploadOptions);
			}),
			tap(
				success => console.log('File uploaded successfully', success),
				error => {
					console.error('Error uploading file:', error);
					console.error('Error status:', error.status);
					console.error('Error message:', error.message);
					console.error('Error headers:', error.headers);
				}
			)
		);

		//return this.http.post<SkyeDriveModel>(this.url, formData, { reportProgress: true, observe: 'events' });
	}

	@ListCacheUpdate()
	put(skyeDrive: SkyeDriveModel): Observable<HttpResponse<SkyeDriveModel>> {
		return this.http.put<SkyeDriveModel>(this.url + '/' + skyeDrive.id, skyeDrive, { observe: 'response' });
	}

	//@ListCacheUpdate()
	patch(url: string, patchDocument: any[]): Observable<any> {
		console.log("patch: ", patchDocument);
		console.log("ur: ", url);
		return this.http.patch(this.url + '/' + url, patchDocument);
	}

	@ListCacheUpdate()
	delete(id: number): Observable<any> {
		return this.http.delete(this.url + '/' + id);
	}
}
