import { Component, HostListener, OnInit } from '@angular/core';
import { SharedService } from 'src/app/shared/shared.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UpDialogComponent, UpDialogRef } from '@ellira/upstrap-dialog';
import { upPhoneNumberValidator } from '@ellira/upstrap-input';

export interface PhoneNumberData {
}

export class PhoneNumberDisplayData {
    phoneNumber: string;
}

@Component({
    templateUrl: './phone-number-dialog.component.html'
})

export class PhoneNumberDialogComponent extends UpDialogComponent<PhoneNumberData> implements OnInit {
    data: any;
    formReady = false;
    detailForm: FormGroup;

    @HostListener('document:keydown', ['$event'])
    onKeydown(e) {
        if (e.key == "Escape" || e.code == "Escape") {
            this.onClose();
        }
    }

    constructor(public dialogRef: UpDialogRef<PhoneNumberDialogComponent>,
        private fb: FormBuilder,
        public sharedService: SharedService) {
        super();
    }

    ngOnInit() {
        this.formReady = false;
        this.init();
    }

    async init() {
        this.detailForm = this.fb.group({
            phoneNumber:  ['', [Validators.required, upPhoneNumberValidator]]
        });
        setTimeout(() => {
            this.formReady = true;
        }, 500);
        
    }

    onClose() {
        this.dialogRef.close(this.data, 'cancel');
    }

    save() {
        this.data.results = this.detailForm.value;
        this.dialogRef.close(this.data, 'save');
    }

}
